import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SmartCamContext } from '../components/SmartCamContext';
import CameraSelect2 from '../components/CameraSelect2';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme, fade } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FaceIcon from '@material-ui/icons/Face';
import LockIcon from '@material-ui/icons/Lock';
import SearchIcon from '@material-ui/icons/Search';
import HourglassEmptyTwoToneIcon from '@material-ui/icons/HourglassEmptyTwoTone';
import VideocamIcon from '@material-ui/icons/Videocam';
import ContactsIcon from '@material-ui/icons/Contacts';
import { useCameraId } from '../components/AutobahnSocket';
import HomeRoute from './HomeRoute';
import NoCam from './NoCam';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        flexGrow: 1,
    },
    cameraSelect: {
        position: 'relative',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
    },
}));

function ResponsiveDrawer(props) {
    const { window } = props;
    const classes = useStyles();
    const history = useHistory()
    const theme = useTheme();
    const smartCamContext = useContext(SmartCamContext)
    const [mobileOpen, setMobileOpen] = useState(false)
    const cameraId = useCameraId()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const logout = async () => {
        history.push('/logout')
    }

    const handleClick = link => {
        history.push(link)
        setMobileOpen(false)
    }

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                <ListItem button key='FaceTimeline' onClick={() => { handleClick('/home/faceTimeline') }}>
                    <ListItemIcon><FaceIcon /></ListItemIcon>
                    <ListItemText primary='Face Timeline' />
                </ListItem>
                <ListItem button key='Contacts' onClick={() => { handleClick('/home/contacts') }}>
                    <ListItemIcon><ContactsIcon /></ListItemIcon>
                    <ListItemText primary='Contacts' />
                </ListItem>
                <ListItem button key='Search' onClick={() => { handleClick('/home/search') }}>
                    <ListItemIcon><SearchIcon /></ListItemIcon>
                    <ListItemText primary='Search' />
                </ListItem>
                <ListItem button key='Waittime' onClick={() => { handleClick('/home/waittime') }}>
                    <ListItemIcon>
                        <HourglassEmptyTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Visit Duration" />
                </ListItem>
                <ListItem button key='Statistic' onClick={() => { handleClick('/home/statistic') }}>
                    <ListItemIcon>
                        <EqualizerIcon />
                    </ListItemIcon>
                    <ListItemText primary="Statistic" />
                </ListItem>
                <ListItem button key='Timecard' onClick={() => { handleClick('/home/timecard') }}>
                    <ListItemIcon><ScheduleIcon /></ListItemIcon>
                    <ListItemText primary='Timecard' />
                </ListItem>
                <ListItem button onClick={() => { handleClick('/home/playback') }}>
                    <ListItemIcon><VideocamIcon /></ListItemIcon>
                    <ListItemText primary='Playback' />
                </ListItem>
                <ListItem button onClick={() => { handleClick('/home/setting') }}>
                    <ListItemIcon><SettingsIcon /></ListItemIcon>
                    <ListItemText primary='Setting' />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={logout}>
                    <ListItemIcon><LockIcon /></ListItemIcon>
                    <ListItemText primary='Logout' />
                </ListItem>
            </List>
            {/* <Divider/>
        <List>
            <ListItem button key='Obsolete' onClick={()=> setOpenObsoleteMenu(!openObsoleteMenu)}>
                <ListItemIcon><DeleteIcon/></ListItemIcon>
                <ListItemText primary='Obsolete' />
                {openObsoleteMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openObsoleteMenu} timeout="auto" unmountOnExit>
                <ListItem button key='Analytic' onClick={()=> setOpenAnalyticMenu(!openAnalyticMenu)}>
                    <ListItemIcon><TimelineIcon/></ListItemIcon>
                    <ListItemText primary='Analytic' />
                    {openAnalyticMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openAnalyticMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    </List>
                </Collapse>
            </Collapse>
        </List> */}
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        {smartCamContext.title}
                    </Typography>

                    <div className={classes.cameraSelect}>
                        <CameraSelect2 />
                    </div>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>

            <main className={classes.content}>
                <div className={classes.toolbar} />

                {cameraId === -1 ?
                    <NoCam/>
                :
                    <HomeRoute/>
                }
            </main>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    window: PropTypes.func,
};

export default ResponsiveDrawer;
