import React, {useState, useEffect, useContext, useRef} from 'react';
import SearchSetting from '../components/SearchSetting';
import { SmartCamContext } from '../components/SmartCamContext';
import FaceTimeline from './FaceTimeline';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export default function Search() {
    const classes = useStyles()

    const [param, setParam] = useState()
    const smartCamContext = useRef(useContext(SmartCamContext))
    useEffect(()=> {
        smartCamContext.current.setTitle('Search')
    },[])

    const handleSearchSubmit = (value) => {
        setParam(value)
    }
    
    
    return (
    <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <Paper className={classes.paper}>
                    <SearchSetting onSubmit={handleSearchSubmit} />
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
                {param && <FaceTimeline filter={param} />}
            </Grid>
        </Grid>
    </div>
  );
}
