import React, { useContext } from 'react';
import { SocketContext, useCameraId, useCameras } from './AutobahnSocket';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        color: theme.palette.background.paper,
        leftPadding: '1rem'
    }
}));

export default function CameraSelect2() {
    const classes = useStyles();
    const cameras = useCameras()
    const cameraId = useCameraId()
    const context = useContext(SocketContext)

    const handleChange = (e) => {
        context.setCameraId(e.target.value)
    };

    return (
    <div>
        <FormControl className={classes.formControl}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cameraId}
                onChange={handleChange}
                className={classes.select}
            >

                { cameraId === -1 && 
                    <MenuItem value={-1}>
                        <div style={{display: 'flex', marginLeft: '0.5rem'}}>
                            <ErrorIcon style={{marginRight: '0.5rem'}}/>Camera is offline
                        </div>
                    </MenuItem> 
                }
                { cameras.map((cam) => {
                    return (
                        <MenuItem value={cam.id} key={cam.id} disabled={!cam.isOnline}>
                            <div style={{display: 'flex', marginLeft: '0.5rem'}}>
                                <CameraAltIcon style={{marginRight: '0.5rem'}}/> {cam.name}
                            </div>
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    
    </div>
    );    
}
