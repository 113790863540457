import React, { useEffect, useContext, useRef } from 'react';
import { SmartCamContext } from '../components/SmartCamContext';
import SettingGeneralTab from '../components/SettingGeneralTab';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }
}));

export default function Setting() {
    const classes = useStyles()

    const smartCamContext = useRef(useContext(SmartCamContext))
    useEffect(()=> {
        smartCamContext.current.setTitle('Setting')
    },[])

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <SettingGeneralTab/>
                </Grid>
            </Grid>
        </div>
    );
}
