import { useState, useCallback } from 'react'
import moment from 'moment'
import qs from 'query-string'

const setQueryStringWithoutPageReload = (qsValue, push) => {
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + qsValue
    if(push)
        window.history.pushState({ path: newurl }, "", newurl)
    else
        window.history.replaceState({ path: newurl }, "", newurl)
}

const setQueryStringValue = (key, value, push) => {
    const values = qs.parse(window.location.search)
    const newQsValue = qs.stringify({ ...values, [key]: value }, {skipNull: true})
    setQueryStringWithoutPageReload(`?${newQsValue}`, push)
}

const getQueryStringValue = (key) => {
    const values = qs.parse(window.location.search)
    return values[key]
}

const useQueryString = (key, initialValue, parser = null) => {

    // read from address bar + parse, or use initialValue
    const stringValue = getQueryStringValue(key)
    const stateValue = parser ? parser.fromQs(stringValue) : stringValue

    // use initial value if falsey
    const [value, setValue] = useState(stateValue || initialValue)

    // on set, also update address bar
    const onSetValue = useCallback(
        (newValue, push = true) => {
            setValue(newValue)
            if(value !== newValue)
                setQueryStringValue(key, parser ? parser.toQs(newValue) : newValue, push)
        },
        [key, parser, value]
    )

    // on restore (pressing back), read from address bar + parse, or use initialValue
    const onRestore = useCallback(
        () => {
            const stringValue = getQueryStringValue(key)
            const stateValue = parser ? parser.fromQs(stringValue) : stringValue
            setValue(stateValue || initialValue)
            setQueryStringValue(key, stringValue, false)
        },
        [key, initialValue, parser]
    )
    return [value, onSetValue, onRestore]
}

const momentToQs = (value) => value ? value.format() : value
const momentFromQs = (stringValue) => stringValue ? moment(stringValue) : stringValue
export const momentParser = { toQs: momentToQs, fromQs: momentFromQs }

const intToQs = (value) => value.toString()
const intFromQs = (stringValue) => parseInt(stringValue)
export const intParser = { toQs: intToQs, fromQs: intFromQs }


export default useQueryString