import React, { useEffect, useState, useRef } from 'react'
import { useCameraId, useSession } from './AutobahnSocket'
import { Rtc } from '../rtc'
import { capitalizeFirstLetter } from '../utils'


export default function VideoView(props) {
    const session = useSession()

    const camId = useCameraId()
    const rtcRef = props.rtcRef
    const onReadyCallback = props.onReadyCallback

    const videoRef = useRef()
    const canvasRef = useRef()
    const [ready, setReady] = useState(false)
    const [msg, setMsg] = useState({})
    const [clearCanvasTimer, setClearCanvasTimer] = useState()

    const freeRtc = () => {
        if (!rtcRef.current)
            return

        Rtc.destroyRtc(rtcRef.current)
        rtcRef.current = null
    }

    const init = () => {
        // add call-back on refresh or close tab to properly tear down rtc
        window.addEventListener('beforeunload', freeRtc)
        return freeRtc
    }
    useEffect(init, [])

    const startRtc = () => {
        if (!videoRef.current || camId === -1)
            return

        const setReadyTrue = () => setReady(true)
        const onDcMsg = (msg) => { setMsg(msg) }
        const asyncFn = async () => {
            const rtcc = await Rtc.createRtc(videoRef.current, session, camId, setReadyTrue, onDcMsg)
            rtcRef.current = rtcc
        }
        asyncFn()
    }

    const onCamIdChange = () => {
        if (!videoRef.current || camId === -1)
            return
        freeRtc()
        startRtc()
    }
    useEffect(onCamIdChange, [camId])

    const onReadyChange = () => {
        if (!ready)
            return
        if (onReadyCallback)
            onReadyCallback()
    }
    useEffect(onReadyChange, [ready])

    const onMsgChange = () => {
        if (msg.type === '/face/result') {
            _drawFaceResult(msg)
        }
    }
    useEffect(onMsgChange, [msg])

    const _drawFaceResult = (msg) => {
        if (!canvasRef.current || !videoRef.current || !msg)
            return

        // make sure canvas and video size matches
        _resizeCanvasToVideoSize()

        let scale = videoRef.current.offsetWidth / videoRef.current.videoWidth
        if (!isFinite(scale))
            return
        let ctx = canvasRef.current.getContext("2d")
        if (!ctx)
            return

        // stop old timeout handle, we will draw new rect and set it anew
        if (clearCanvasTimer)
            clearTimeout(clearCanvasTimer)

        // clear old rects
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)

        // draw all rects
        ctx.lineWidth = 6 * scale
        let fontSize = ~~(40 * scale)
        let fontHeight = 24 * scale
        let boxHeight = 48 * scale
        let margin = 12 * scale
        ctx.font = fontSize + "px sans-serif"
        msg.rects.forEach(r => {
            r.x *= scale
            r.y *= scale
            r.w *= scale
            r.h *= scale

            let label = ''
            let unknown = false

            if (!r.label) {
                unknown = true
                label = 'Unknown'
            } else {
                label = capitalizeFirstLetter(r.label)
            }

            // draw rect
            ctx.strokeStyle = unknown ? '#FF5733': "#3DA9C0"
            ctx.strokeRect(r.x, r.y, r.w, r.h)

            // draw background for text
            let textWidth = ctx.measureText(label).width
            ctx.fillStyle = unknown ? '#FF5733A0': "#39A7C1A0"
            ctx.fillRect(r.x, r.y - boxHeight, textWidth + 2 * margin, boxHeight)

            // draw text
            ctx.fillStyle = "#0C2330"
            ctx.fillText(label, r.x + margin, r.y + (boxHeight + fontHeight) / 2 - boxHeight)
        })

        // set timeout in case there is no more detection
        let clearCanvasTimeoutHandle = setTimeout(() => {
            if (canvasRef.current)
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
        }, 500)
        setClearCanvasTimer(clearCanvasTimeoutHandle)
    }

    const _resizeCanvasToVideoSize = () => {

        if (!canvasRef.current || !videoRef.current)
            return

        canvasRef.current.width = videoRef.current.offsetWidth
        canvasRef.current.height = videoRef.current.offsetHeight
    }

    return (
        <div>
            <canvas ref={canvasRef} style={{ 'position': 'absolute', 'zIndex': 1 }} />
            <video ref={videoRef} width="100%" autoPlay playsInline muted></video>
        </div>
    )
}
