import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import moment from 'moment'

import { useCameraId, useSession } from '../components/AutobahnSocket';
import DashboardCard from '../components/DashboardCard';
import AnalyticSetting from '../components/AnalyticSetting';
import DayCard from '../components/DayCard';
import { SmartCamContext } from '../components/SmartCamContext';

import { grey, blue } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CircularProgress } from '@material-ui/core';
import { callCam } from '../utils';
import CustomerStackBarChart from '../components/CustomerStackBarChart';
import useQueryString, { momentParser } from '../useQueryString'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    facepaper: {
        padding: theme.spacing(1),

    },
    bar: {
        height: "1rem"
    },
    chartPaper: {
        height: "100%",
        padding: theme.spacing(3)
    },
    gridDayCard: {
        marginTop: theme.spacing(2)
    }
}));

export default function Statistic () {
    const classes = useStyles()
    const session = useSession()
    const cameraId = useCameraId()

    const [indicator, setIndicator] = useState('customers');
    const [statResult, setStatResult] = useState()
    const [loading, setLoading] = useState(false)

    // qs
    const [toDate, setToDate, restoreToDate] = useQueryString('toDate', moment().endOf('day'), momentParser)

    // register back button
    window.onpopstate = () => {
        restoreToDate()
    }

    const fromDate = moment(toDate).subtract(6, 'days').startOf('day')

    const smartCamContext = useRef(useContext(SmartCamContext))
    useEffect(()=> {
        smartCamContext.current.setTitle('Statistics')
    },[])

    async function fetchStat () {

        if (cameraId === -1 || !fromDate || !toDate)
            return

        setStatResult()
        setLoading(true)

        let currentDate = moment(fromDate)
        let results = []
        
        while (currentDate <= toDate) {

            // query this period
            let payload = {
                date: currentDate.format(),
            }
            let res = await callCam(session, cameraId, 'stat.customer', payload)
            if (!res) {
                setLoading(false)
                return
            }
            let aResult = {
                date: currentDate.format(),
                new: res.new,
                total: res.total
            }
            results.push(aResult)
            currentDate = currentDate.add(1, 'days')
        }
        setStatResult(results)
        setLoading(false)
    }
    const fetchStatCallback = useCallback(fetchStat, [session, cameraId, toDate])

    useEffect(()=> {
        fetchStatCallback()
    }, [fetchStatCallback])

    const handleSettingChange = (toDate) => {
        if(!toDate)
            return
        setToDate(toDate)
    }

    let sumCustomers = {
        newCurrent: 0,
        totalCurrent: 0,
    }
    let plotData = []
    if (statResult) {
        statResult.map((day, index) => {
            sumCustomers.newCurrent += day.new
            sumCustomers.totalCurrent += day.total

            let plotRow = {
                date: moment(day.date).format('ddd D MMM'),
                Return: 0,
                New: 0
            }
            if (indicator === 'customers') {
                plotRow.Return = day.total - day.new
                plotRow.New = day.new
            } 
            plotData.push(plotRow)

            return day
        })
    } 

    return (
    <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <Paper className={classes.paper}>
                    <AnalyticSetting toDate={toDate} onChange={handleSettingChange}/>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>

                {statResult ? 
                    <Grid container spacing={3} style={{height: "100%"}}>
                        <Grid item xs={12}>
                            <Paper className={classes.facepaper}>
                                { loading ? <CircularProgress style={{marginLeft: '50%'}}/> : ''}
                                { fromDate && 
                                    <Typography variant="overline" display="block" gutterBottom align="center" style={{marginTop: "1rem"}}>
                                        Period between {fromDate.format('D/M/YYYY')} and {moment(toDate).format('D/M/YYYY')}
                                    </Typography>   
                                
                                }
                                <Grid container direction="row" justify="center" style={{padding: "1rem"}}>
                                    <Grid item xs={4} sm={4} md={4} lg={2} xl={2}>
                                        <DashboardCard featureName="CUSTOMERS" value={sumCustomers.totalCurrent} 
                                            valueCompare={ sumCustomers.totalDelta > 0 ? `+${sumCustomers.totalDelta}` : sumCustomers.totalDelta} />
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={10} xl={2} className={classes.barPlot}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} >
                                                <StatBar labelLeft="new customers" labelRight="returned" 
                                                    value={sumCustomers.totalCurrent !== 0 ? sumCustomers.newCurrent / sumCustomers.totalCurrent : 0} color={blue}/>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <StatBar labelLeft="male" labelRight="female" value={0.5} color={grey}/>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <StatBar labelLeft="young" labelRight="old" value={0.5} color={grey}/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper className={classes.chartPaper}>
                                <Grid container direction="row" style={{height: "100%"}} wrap="wrap">
                                    <Grid item xs={12}>
                                        <Tabs
                                            value={indicator}
                                            onChange={(event, newValue) => setIndicator(newValue)}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            centered
                                        >
                                            <Tab label="Customers" value='customers'/>
                                            <Tab label="Gender" disabled />
                                            <Tab label="Age" disabled />
                                        </Tabs>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container className={classes.gridDayCard} direction='row' noWrap spacing={1} justify="space-evenly">
                                            { statResult && statResult.map( (day, index) => {
                                                let currentValue;
                                                if (indicator === 'customers') {
                                                    currentValue = day.total
                                                } 
                                                return (
                                                    <Grid item xs key={index}>
                                                        <DayCard day={moment(day.date).format('ddd')} currentValue={currentValue} showDiff={false}/>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{height: "100%", maxHeight: "300px", paddingTop:"1rem"}} xs={12}>
                                        <CustomerStackBarChart data={plotData}/>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={3} style={{height: "100%"}}>
                        <Grid item xs={12}>
                            { loading ? <CircularProgress style={{marginLeft: '50%', marginTop: '20%'}}/> : ''}
                        </Grid>
                    </Grid>
                }       
            </Grid>
        </Grid>
    </div>
  );
}

function StatBar ({labelLeft, labelRight, value, color}) {
    const classes = useStyles()

    return(
        <Container style={{display: 'flex', flexDirection: 'row', width: '100%', flexWrap: 'wrap'}}>
            <div className={classes.bar} style={{ backgroundColor: color[400], width: parseInt(value*100) + '%'}}></div>
            <div className={classes.bar} style={{ backgroundColor: color[100], width: 100 - parseInt(value*100) + '%'}}></div>
            <div className={classes.barLabel} style={{ width: '50%'}}>
                {labelLeft.toUpperCase() + ` ${parseInt(value*100)}%`} 
            </div>
            <div className={classes.barLabel} style={{ width: '50%', textAlign: 'right'}}>
                {labelRight.toUpperCase() + ` ${100 - parseInt(value*100)}%`}
            </div>
        </Container>
    )
}