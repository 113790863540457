import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SmartCamContext } from '../components/SmartCamContext';
import VideoViewWithControl from '../components/VideoViewWithControl';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    facepaper: {
        padding: theme.spacing(2),
    },
    playbutton: {
        marginTop: theme.spacing(2),
    }

}));

export default function Playback() {
    const classes = useStyles()

    const [tmpDate, setTmpDate] = useState(new Date())
    const [datetime, setDatetime] = useState()
    const [showWarning, setShowWarning] = useState(false)

    const smartCamContext = useRef(useContext(SmartCamContext))
    useEffect(()=> {
        smartCamContext.current.setTitle('Playback')
    },[])

    const handleDateChange = (date) => {
        date.setSeconds(0,0)
        setTmpDate(date)
    }

    const onVideoError = () => {
        setShowWarning(true)
    }

    const onVideoSuccess = () => {
        setShowWarning(false)
    }

    const onPlayClick = () => {
        setDatetime(tmpDate)
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                
                {/* left column */}
                <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                    <Paper className={classes.paper} style={{textAlign: 'left'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Date"
                                    value={tmpDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    style={{width:'100%'}}
                                />
                                <KeyboardTimePicker
                                    autoOk
                                    ampm={false}
                                    margin="normal"
                                    variant="inline"
                                    id="time-picker"
                                    label="Time"
                                    value={tmpDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    style={{width:'100%'}}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>

                        {/* right align button */}
                        <Grid container direction="row" justify="space-between" alignItems="flex-end" >
                            <div/>
                            <Button className={classes.playbutton} onClick={onPlayClick} variant="contained" color="primary">Play</Button>
                        </Grid>
                    </Paper>
                </Grid>

                {/* right column */}
                <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
                    <Grid container spacing={1}>
                        {showWarning && <Grid item xs={12}>
                            <Alert severity="warning"> There is no video available at {moment(datetime).format('D ddd MMM, HH:mm')}. Now playing live view</Alert>
                        </Grid>}

                        {/* place holder */}
                        <Grid item xs={3}/>

                        <Grid item xs={12}>
                            <Paper className={classes.facepaper}>
                                <VideoViewWithControl datetime={datetime} onVideoError={onVideoError} onVideoSuccess={onVideoSuccess}/>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        </div>
    );
}
