import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment'
import { useCameraId, useSession } from './AutobahnSocket';
import { callCam, capitalizeFirstLetter } from '../utils';
import qs from 'query-string'

import { CardActions, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import { useHistory } from 'react-router-dom';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '75%', // 16:9
    },
    tag: {
        margin: theme.spacing(0.5),
    },
}));


export default function ContactCard ({ labelId }) {
    const classes = useStyles();
    const history = useHistory()
    const cameraId = useCameraId()
    
    const session = useSession()
    const [personInfo, setPersonInfo] = useState()


    const fetchFace = async () => {
        let payload = {
            id: labelId
        }
        const res = await callCam(session, cameraId, 'label.findbyid', payload)
        if (!res)
            return

        setPersonInfo(res.label)
    }
    const fetchFaceCallback = useCallback(fetchFace, [session, cameraId, labelId])

    useEffect(()=> {
        fetchFaceCallback()
    }, [fetchFaceCallback])

    const handleCardClick = () => {
        if (!labelId)
            return

        const labelIdStr = qs.stringify({'labelId': labelId})
        const toDateStr = qs.stringify({'toDate': moment().endOf('day').format()})
        const resolutionStr = qs.stringify({'resolution': 'Week'})
        history.push(`/home/facehistory?${labelIdStr}&${toDateStr}&${resolutionStr}`)
    }

    return !personInfo ? null : (
        <Card className={classes.root}>
            <CardActionArea onClick={handleCardClick}>
                <CardMedia className={classes.media} image={personInfo.jpgData && personInfo.jpgData} title={personInfo && personInfo.name} />
                <CardContent style={{paddingBottom: 0}}>
                    <Typography variant="h5" component="h2">
                        <b>{capitalizeFirstLetter(personInfo.name)} </b>
                    </Typography>
                </CardContent>
                <CardActions>
                    <div>
                        {personInfo.tags.length > 0 ? personInfo.tags.map(tag => {
                            return <Chip key={tag.id} className={classes.tag} label={tag.name} size="small"/>
                        }):
                            <Typography className={classes.tag} variant="body2" color="textSecondary" component="p">No tags</Typography>
                        }
                    </div>
                </CardActions>
            </CardActionArea>
        </Card>
    )
}
