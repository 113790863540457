import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    // width: '100%',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },

});

export default function DayCard({day, currentValue = 0, previousValue = 0, showDiff}) {
  const classes = useStyles();

    const isPositive = currentValue > previousValue
    let percentage 
    if (previousValue === 0){
        percentage = '-'
    } else {
        percentage = parseInt((currentValue - previousValue) / previousValue * 100).toString() + "%"
    }

    const delta = currentValue - previousValue

    return (
        <Card className={classes.root} variant="outlined">
        <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                {day.toUpperCase()}
            </Typography>
            <Typography variant="h3" component="h3" align="right" style={{fontWeight: 'bold', paddingTop: '-0.5rem'}}>
                {currentValue}
            </Typography>
            <Typography variant="body2" component="p" align="right" style={{color: isPositive ? "green" : "red"}}>
                { showDiff && 
                    <React.Fragment>
                        { delta > 0 && '+'}{delta} ({percentage})
                    </React.Fragment>
                }
            </Typography>
        </CardContent>
        </Card>
    );
}
