import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useCameraId, useSession } from './AutobahnSocket'
import { callCam, capitalizeFirstLetter } from '../utils'

import { Button, Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        maxWidth: 500,
        marginBottom: theme.spacing(2)
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    margin: {
        margin: theme.spacing(1),
    },
    textFieldContainer: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: 'black',
        },
    },
}))



export default function IdCard ({ labelId, onDeleteClick = null, onEditTagClick = null, refresh=false }) {
    const classes = useStyles()
    const session = useSession()
    const cameraId = useCameraId()

    const [personInfo, setPersonInfo] = useState()
    const [editing, setEditing] = useState(false)
    const [unknownFace, setUnknownFace] = useState(true)

    async function fetchFace() {
        let payload = {
            id: labelId
        }
        const res = await callCam(session, cameraId, 'label.findbyid', payload)
        if (!res) {
            console.log('ERROR cam.label.findbyid')
            return
        }
        // check if unknown face
        if (!res.label.name) {
            setUnknownFace(true)
        } else {
            setUnknownFace(false)
        }
        setPersonInfo(res.label)
       
    }
    const fetchFaceCallback = useCallback(fetchFace,  [session, cameraId, labelId])

    useEffect(()=> {
        fetchFaceCallback()
    }, [fetchFaceCallback, refresh])
        
    
    const onEditClick = () => {
        if (editing) {
            setEditing(false)
            fetchFace()
        } else {
            setEditing(true)
        }
    }

    const handleSubmit = async () => {
        const payload = personInfo
        if (payload.birthday) {
            payload.birthday = moment(payload.birthday).format('YYYY-MM-DD')
        }
        const res = await callCam(session, cameraId, 'label.updateinfo', payload)
        if (!res || !res.ok) {
            console.log('ERROR label.updateinfo')
            return
        }
        setEditing(false)
    }

    // id card of unknown face is well-defined (images are ambiguous), so return null
    return ( unknownFace ? null :
        <Paper className={classes.paper} >
            <Grid container spacing={2}>

                <Grid item xs={5} container direction="column">
                    <Grid item>
                        <img className={classes.img} src={personInfo && personInfo.jpgData} alt=""/>
                    </Grid>
                    { !unknownFace && 
                        <Grid item>
                            <Typography variant="body2" gutterBottom>
                                Tags
                                <IconButton aria-label="edit" className={classes.margin} size="small" onClick={ () => onEditTagClick(labelId)} color="primary">
                                    <EditIcon fontSize="inherit" />
                                </IconButton>
                            </Typography>
                            {personInfo &&
                                personInfo.tags.length > 0 ?
                                personInfo.tags.map(tag => {
                                    return (
                                        <Chip className={classes.tag} label={tag.name} key={tag.id} style={{marginRight: '0.5rem', padding: '0 0.3rem', marginBottom: '0.5rem'}} size="small"/>
                                    )
                                })
                                : 'No tags'
                            }
                        </Grid>
                    }
                </Grid>
         
                {!unknownFace && 
                    <Grid item xs={7} container direction="column">
                        <Grid item container justify="flex-end">
                            <Grid item>
                                <IconButton aria-label="edit" className={classes.margin} size="small" onClick={onEditClick} color="primary">
                                    <EditIcon fontSize="inherit" />
                                </IconButton>
                                <IconButton aria-label="edit" className={classes.margin} size="small" onClick={() => onDeleteClick(labelId)} color="secondary">
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item xs className={classes.textFieldContainer}>
                            <TextField
                                disabled={!editing}
                                label="Firstname"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                value={personInfo ? capitalizeFirstLetter(personInfo.name) : ''}
                                onChange={(e) => setPersonInfo({...personInfo, name: e.target.value })}
                            />
                            <TextField
                                disabled={!editing}
                                label="Lastname"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                value={personInfo && personInfo.lastname ? capitalizeFirstLetter(personInfo.lastname) : ''}
                                onChange={(e) => setPersonInfo({ ...personInfo, lastname: e.target.value })}
                            />
                            
                            <TextField
                                disabled={!editing}
                                label="Mobile"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                value={personInfo && personInfo.mobile ? personInfo.mobile : ''}
                                onChange={(e) => setPersonInfo({ ...personInfo, mobile: e.target.value})}
                            />
                            <TextField
                                disabled={!editing}
                                label="Address"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                value={personInfo && personInfo.address ? personInfo.address : ''}
                                onChange={(e) => setPersonInfo({ ...personInfo, address: e.target.value})}
                            />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className={classes.customDatepicker}
                                    disabled={!editing}
                                    label="Birthday"
                                    fullWidth
                                    margin="dense"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    size="small"
                                    format="dd/MM/yyyy"
                                    value={personInfo && personInfo.birthday ? personInfo.birthday : null}
                                    onChange={(e) => setPersonInfo({ ...personInfo, birthday: e })}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <TextField
                                disabled={!editing}
                                label="ID"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                value={personInfo && personInfo.citizenId ? personInfo.citizenId : ''}
                                onChange={(e) => setPersonInfo({ ...personInfo, citizenId: e.target.value })}
                            />
                            <TextField
                                disabled={!editing}
                                label="Note"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                size="small"
                                value={personInfo && personInfo.note ? personInfo.note : ''}
                                onChange={(e) => setPersonInfo({ ...personInfo, note: e.target.value,})}
                            />
                            {editing && <Button variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: '1rem'}}>Submit</Button>}
                        </Grid>
                    </Grid>
                }
                { unknownFace &&
                    <Grid item xs={7} container direction="column" justify='center' alignItems='center'>
                        <Grid item >
                            <Typography variant="h5" gutterBottom >
                                Unknown Face
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Paper>
   
  )
}
