import React, { useEffect, useState } from 'react';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, BarChart, Bar, Label, Cell
} from 'recharts';
import { scaleLog } from 'd3-scale';

export function WaittimeChartDaily ({ data, onClick, selectedIdx }) {

    const [activeTooltipIndex, setActiveTooltipIndex] = useState(selectedIdx)

    useEffect(() => {
        setActiveTooltipIndex(selectedIdx)
    }, [selectedIdx])

    const handleClick = (e) => {
        if (!e)
            return
        onClick(e.activeTooltipIndex)
    }

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart width={730} height={250} data={data} onClick={handleClick} maxBarSize={40}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <XAxis dataKey="durationText" />
                <YAxis>
                    <Label value="Duration (minutes)" position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
                </YAxis>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip cursor={{ fill: "#E6FAFF88" }} content={() => null}/>
                <Legend />
                <Bar dataKey="average" fill="#00659A">
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={index === activeTooltipIndex ? "#00A5CA" : "#00659A"}/>
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}


export function WaittimeChartDistribution ({ data, onClick, selectedIdx }) {

    const [activeTooltipIndex, setActiveTooltipIndex] = useState(selectedIdx)

    useEffect(() => {
        setActiveTooltipIndex(selectedIdx)
    }, [selectedIdx])

    const handleClick = (e) => {
        if (!e)
            return
        onClick(e.activeTooltipIndex)
    }

    const scale = scaleLog().base(10)

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart width={730} height={250} data={data} onClick={handleClick} maxBarSize={40}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <XAxis dataKey="durationText" />
                <YAxis scale={scale} domain={[1, 'dataMax']} allowDataOverflow interval={10}/>
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip cursor={{ fill: "#E6FAFF88" }} content={() => null}/>
                <Legend />
                <Bar dataKey="count" fill="#00659A">
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={index === activeTooltipIndex ? "#00A5CA" : "#00659A"}/>
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
