import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Avatar, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { useSession, useCameraId } from '../components/AutobahnSocket';
import { SmartCamContext } from '../components/SmartCamContext';
import TimecardSetting from '../components/TimecardSetting';
import { capitalizeFirstLetter } from '../utils';
import {callCam} from '../utils'
import useQueryString, { momentParser } from '../useQueryString';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: 500,
      },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    tableCell: {
        fontSize: '1.5rem'
    }
}));

const officeHour = {
    begin: minutesOfDay(moment('09:00', 'hh:mm')),
    end: minutesOfDay(moment('18:00', 'hh:mm'))
}

function minutesOfDay (m){
    return m.minutes() + m.hours() * 60;
}

export default function Timecard () {
    const classes = useStyles()
    const cameraId = useCameraId()
    const session = useSession()
    const [result, setResult] = useState([])
    const [loading, setLoading] = useState(false)

    // qs
    const [fromDate, setFromDate, restoreFromDate] = useQueryString('fromDate', moment().subtract(2, 'days').startOf('day'), momentParser)
    const [toDate, setToDate, restoreToDate] = useQueryString('toDate', moment().endOf('day'), momentParser)

    // register back button
    window.onpopstate = () => {
        restoreFromDate()
        restoreToDate()
    }

    const smartCamContext = useRef(useContext(SmartCamContext))
    useEffect(()=> {
        smartCamContext.current.setTitle('Timecard')
    },[])

    const fetchTimecard = async () => {

        if (cameraId === -1 || !fromDate || !toDate)
            return

        setLoading(true)

        let results = []
        let currentDate = moment(fromDate)
        while (currentDate < toDate) {

            // query this period
            let payload = {
                date: currentDate.format()
            }
            let res = await callCam(session, cameraId, 'timecard', payload)
            if (!res || !res.timecard) {
                setLoading(false)
                return
            }
            let timecard = res.timecard
            timecard.map( p => {
                p.clockIn = moment(p.clockIn)
                p.clockOut = moment(p.clockOut)
                p.name = capitalizeFirstLetter(p.name)
                return p
            })
            timecard.sort( (a,b) => {
                const nameA = a.name.toUpperCase()
                const nameB = b.name.toUpperCase()
                return nameA < nameB ? -1 : 1
            })

            let aResult = {
                date: moment(currentDate),
                timecard: timecard,
            }
            results.push(aResult)

            currentDate.add(1, 'days')
        }
        setResult(results)
        setLoading(false)
    }
    const fetchTimecardCallback = useCallback(fetchTimecard, [fromDate, toDate, session, cameraId])

    useEffect(()=> {
        fetchTimecardCallback()
    }, [fetchTimecardCallback])
    

    const handleSearchParameter = async (fromDate, toDate) => {
        if (!fromDate || !toDate || fromDate > toDate)
            return

        setFromDate(fromDate)
        setToDate(toDate)
    }

    return (
    <div className={classes.root}>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                <Paper className={classes.paper}>
                    <TimecardSetting fromDate={fromDate} toDate={toDate} onChange={handleSearchParameter}/> 
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
                <Grid container spacing={3} style={{height: "100%"}} justify="center">
                    <Grid item xs={12} >
                        { loading ? <CircularProgress /> : ''}
                        { result && result.map ((row, index) => {
                            return (
                                <div key={index + '-' + row.date.format('D-M-YYYY')}>
                                    <Typography variant="h6" component="h6">
                                        {row.date.format('ddd D MMM')}
                                    </Typography>
                                    { row.timecard.length > 0 && 
                                        <TimeTable data={row.timecard} />
                                    }
                                    { row.timecard.length === 0 && 
                                        <Typography variant="body2" component="h6" style={{width: '100%', textAlign: 'center', margin: '1.5rem 0',}}>
                                            NO STAFF
                                        </Typography>
                                    }
                                </div>
                            )})
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
  );
}


function TimeTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} style={{margin: '1.5rem 0', marginLeft: '1rem'}}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell align="center">Staff Name</TableCell>
                <TableCell align="right">Check-in Time</TableCell>
                <TableCell align="right">Late Check-in</TableCell>
                <TableCell align="right">Check-out Time</TableCell>
                <TableCell align="right">Total hours</TableCell>
                <TableCell align="right">OT hours</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row, index) => {
            const duration = moment.duration(row.clockOut.diff(row.clockIn))
            let min = parseInt(duration.asMinutes()%60)
            min = min >= 10 ? min : '0' + min.toString()
            const isLateCheckin = minutesOfDay(row.clockIn) > officeHour.begin
            let ot =  (minutesOfDay(row.clockOut) - officeHour.end) / 60 
          
            return(
                <TableRow key={index}>
                <TableCell align="center">
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item >
                            <Avatar src={row.b64Jpg} className={classes.large}/>
                            {row.name}
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>{ row.clockIn.format('H:mm') }</TableCell>
                <TableCell align="right" className={classes.tableCell} style={{ color: isLateCheckin ? 'red': 'black'}}>{ isLateCheckin ? 'Late' : '-' }</TableCell>
                <TableCell align="right" className={classes.tableCell}>{ row.clockOut.format('H:mm') }</TableCell>
                <TableCell align="right" className={classes.tableCell}>{ parseInt(duration.asHours()) }:{ min }</TableCell>
                <TableCell align="right" className={classes.tableCell}>{ ot > 0 ? ot.toFixed(2) : '-' }</TableCell>
                </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
