import React, {useEffect, useState} from 'react';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Bar, BarChart, Cell, Label
} from 'recharts';
import moment from 'moment'

export default function FaceTimelineChart ({ data, onBarClick, resolution, yLabel, selectedDate }) {

    const selectedDateStr = selectedDate ? selectedDate.format() : null
    const idx = data && selectedDateStr ? data.findIndex(d => d.date === selectedDateStr) : null
    const [activeTooltipIndex, setActiveTooltipIndex] = useState(idx)

    useEffect(() => {
        const selectedDateStr = selectedDate ? selectedDate.format() : null
        const idx = data && selectedDateStr ? data.findIndex(d => d.date === selectedDateStr) : null
        setActiveTooltipIndex(idx)
    }, [selectedDate, data])

    const handleBarClick = (e) => {
        if (!e || !e.activeLabel)
            return
        onBarClick(e.activeLabel)
    }

    const formatXAxis = (tickItem) => {
        return resolution === 'Week' ? moment(tickItem).format('ddd D MMM') : moment(tickItem).format('H:mm')
    }

    return ( data &&
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} onClick={handleBarClick} maxBarSize={40}>
                <XAxis dataKey="date" tickFormatter={formatXAxis}/>
                <YAxis allowDecimals={false}>
                    <Label value={yLabel} position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
                </YAxis>
                <CartesianGrid y={1} strokeDasharray="3 3" />
                <Tooltip cursor={{ fill: "#E6FAFF88" }} content={() => null}/>
                <Bar dataKey= "countVisitor">
                    {
                        data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={index === activeTooltipIndex ? "#00A5CA" : "#00659A"}/>
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );

}
