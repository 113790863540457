import 'date-fns'
import moment from 'moment'
import React, { useState } from 'react'
import TagFilter from './TagFilter'

import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import { Button, IconButton, Input, InputAdornment, InputLabel, TextField } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

export default function SearchSetting({ onSubmit = null }) {

    const [fromDate, setFromDate] = useState(moment().subtract(2,'days').startOf('day'))
    const [toDate, setToDate] = useState(moment().endOf('day'))
    const [tagId, setTagId] = useState(0)
    const [nameKeyword, setNameKeyword] = useState('')
    const [image, setImage] = useState()
    const [imageName, setImageName] = useState('')
    const [enableSubmit, setEnableSubmit] = useState(true)

    const reader = new FileReader()
    reader.addEventListener("load", () => {
        setEnableSubmit(true)
        setImage(reader.result)
    }, false)

    const handleDateChange = (source, date) => {
        if (source === 'from') {
            const newFrom = moment(date).startOf('day')
            setFromDate(newFrom)
            if(toDate < newFrom)
                setToDate(newFrom.endOf('day'))
        } else if (source === 'to') {
            const newTo = moment(date).endOf('day')
            setToDate(newTo)
            if (fromDate > newTo)
                setFromDate(newTo.startOf('day'))
        }
    }

    const handleTagSelected = (tagId) => {
        setTagId(tagId)
    }

    const handleFileChange = (e) => {
        if(!e.target.files.length){
            setImageName('')
            setImage()
            return
        }
        setEnableSubmit(false)
        setImageName(e.target.files[0].name)
        reader.readAsDataURL(e.target.files[0])
    }

    const handleSubmit = () => {
        onSubmit({
            fromDate: fromDate,
            toDate: toDate,
            tagId: tagId,
            keyword: nameKeyword,
            image: image
        })
    }

    return (
        <div>

            {/* search by string */}
            <TextField id="standard-basic" label="Keywords" placeholder="Name, mobile, etc." value={nameKeyword} onChange={(e) => setNameKeyword(e.target.value)} style={{width: '100%', paddingBottom: '1rem'}}/>

            {/* search by image */}
            <FormControl fullWidth style={{width: '100%', paddingBottom: '0.5rem'}}>
                <InputLabel>Image</InputLabel>
                <Input
                    type='text'
                    value={imageName}
                    endAdornment={
                        <InputAdornment position="end">
                            <input accept="image/jpeg" hidden id="file-input-id" type="file" onChange={handleFileChange}/>
                                <label htmlFor="file-input-id">
                                <IconButton component="span">
                                    <ArrowForwardIcon/>
                                </IconButton>
                            </label> 
                        </InputAdornment>
                    }
                />
            </FormControl>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="fromDate"
                    label="From Date"
                    value={fromDate.format('YYYY-MM-DD')}
                    onChange={(date) => handleDateChange('from', date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{width:'100%'}}
                />
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="toDate"
                    label="To Date"
                    value={toDate.format('YYYY-MM-DD')}
                    onChange={(date) => handleDateChange('to', date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{width:'100%'}}
                />
            </MuiPickersUtilsProvider>
            <TagFilter onTagSelect={handleTagSelected} tagId={tagId}/>
            <div style={{textAlign: 'right', marginTop: '2.5rem'}}>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!enableSubmit}>
                    Go!
                </Button>
            </div>
        </div>
    )
}
