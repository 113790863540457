import React from 'react';
import {
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Bar, BarChart, Label
} from 'recharts';

export default function CustomerStackBarChart ({ data }) {

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart width={730} height={250} data={data}  maxBarSize={40}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis >
                    <Label value="Customers (people)" position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
                </YAxis>
                <Tooltip cursor={{ fill: "#E6FAFF88" }}/>
                <Legend />
                <Bar dataKey="New" stackId="a" fill="#00659A" />
                <Bar dataKey="Return" stackId="a" fill="#00A5CA" />

            </BarChart>
        </ResponsiveContainer>
      
    );
}
