import React, {useCallback, useEffect, useState} from 'react';
import { useCameraId, useSession } from './AutobahnSocket';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import { callCam } from '../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default function TagFilter({ onTagSelect, tagId }) {
    const classes = useStyles();
    const session = useSession()
    const cameraId = useCameraId()
    const [tags, setTags] = useState([])
    const [selectedTag, setSelectedTag] = useState(tagId)

    const handleClick = (id) => {
        setSelectedTag(id)
        onTagSelect(id)
    };

    const fetchTag = async () => {
        if (!cameraId)
            return

        const res = await callCam(session, cameraId, 'tag.list')
        if (!res)
            return
        let tags = [ {id: 0, name: 'ALL'} ]
        res.tags.map( tag => {
            if ( tag.name.substring(0,7) !== 'Reserve') {
                tags.push(tag)
            }
            return tag
        })
        setTags(tags)
    }
    const fetchTagCallback = useCallback(fetchTag, [session, cameraId])

    useEffect( () => {
        fetchTagCallback()
    },[fetchTagCallback])

    return (
        <div>
            <Typography variant="overline" display="block" gutterBottom align="left" style={{marginTop: "1rem"}}>
                Tags
            </Typography>
            <div className={classes.root}>
                {tags && tags.map( tag => {
                    return(
                        <Chip key={`${cameraId}-${tag.id}`} label={tag.name} onClick={() => handleClick(tag.id)} color={tag.id === selectedTag ? 'primary' : 'default'} size="small" style={{padding: '0 0.4rem'}}/>
                    )
                })}
                {tags && tags.length === 0 && 
                    <Typography variant="subtitle2" display="block" gutterBottom align="left">
                        Found No Tags
                    </Typography>
                }
                
            </div>
        </div>
    );
}
