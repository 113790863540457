import React, {useState, useEffect, useCallback} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";

import { makeStyles } from '@material-ui/core/styles';
import Facecard from './Facecard';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    facepaper: {
        padding: theme.spacing(2),

    },

    infiniteScroll: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    card:{
        margin: theme.spacing(2),
    }

}));

export default function TrackInfiniteScroll(props) {
    const classes = useStyles()

    const [hasMore, setHasMore] = useState(true)
    const [offset, setOffset] = useState(0)
    const [trackOnDisplay, setTrackOnDisplay] = useState([])

    const batchSize = 30
    const trackIds = props.trackIds
    const validPeriod = props.validPeriod
    const onCardClicked = props.onCardClicked


    const updateTrackOnDisplay = () => {
        let newTrackOnDisplay = trackIds.slice(0, offset + batchSize)   // good guy js gives no index error

        setTrackOnDisplay(newTrackOnDisplay)
        setOffset(newTrackOnDisplay.length)
        setHasMore(newTrackOnDisplay.length === trackOnDisplay.length)
    }
    const updateTrackOnDisplayCallback = useCallback(updateTrackOnDisplay, [trackIds])

    useEffect(()=> {
        setTrackOnDisplay([])
        setOffset(0)
        setHasMore(true)
        updateTrackOnDisplayCallback()
    }, [updateTrackOnDisplayCallback])


    return (
        <React.Fragment>  
            <InfiniteScroll
                dataLength={trackOnDisplay.length}
                next={updateTrackOnDisplayCallback}
                hasMore={hasMore}
                className={classes.infiniteScroll}
                style={{paddingTop:'1rem'}}
                >
                { trackOnDisplay && trackOnDisplay.map( trackId => {
                    return (
                        <Facecard key={trackId} trackId={trackId} isKnownFace={false} onClick={onCardClicked}  validPeriod={validPeriod}/>
                    )
                })}
            </InfiniteScroll>
        </React.Fragment>
  );
}
