import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';

import { useCameraId, useSession } from '../components/AutobahnSocket';
import { SmartCamContext } from '../components/SmartCamContext';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ContactCard from '../components/ContactCard';
import { callCam } from '../utils';
import AddFaceJpgModal from '../components/AddFaceJpgModal'
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    }
}));

export default function Contacts() {
    const classes = useStyles()
    const cameraId = useCameraId()
    const session = useSession()
    const smartCamContext = useRef(useContext(SmartCamContext))

    const [labels, setLabels] = useState()
    const [showAddFaceModal, setShowAddFaceModal] = useState(false)

    useEffect(() => {
        smartCamContext.current.setTitle('Contacts')
    }, [])

    const fetchLabel = async () => {
        if (cameraId === -1)
            return

        const res = await callCam(session, cameraId, 'label.list')
        if (!res)
            return
        let labels = res.labels
        labels.sort((a, b) => {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            return nameA < nameB ? -1 : 1
        })

        setLabels(labels)
    }
    const fetchLabelCallback = useCallback(fetchLabel, [session, cameraId])

    useEffect(() => {
        fetchLabelCallback()
    }, [fetchLabelCallback])

    const handleAddFaceClose = () => {
        setShowAddFaceModal(false)
    }

    const handleAddFaceSubmit = () => {
        setShowAddFaceModal(false)
        fetchLabelCallback()
    }

    const handleAddClick = () => {
        setShowAddFaceModal(true)
    }

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>

                {labels && labels.map(l => {
                    return (
                        <Grid item xs={6} sm={4} md={3} lg={2} xl={2} key={l.id}>
                            <ContactCard labelId={l.id} />
                        </Grid>
                    )
                })}

            </Grid>
            <Fab color="primary" className={classes.fab} onClick={handleAddClick}>
                <AddIcon/>
            </Fab>
            <AddFaceJpgModal open={showAddFaceModal} onClose={handleAddFaceClose} onSubmit={handleAddFaceSubmit} />
        </div>
    );
}
