import React, { useEffect, useState, useRef, useCallback } from 'react';
import VideoView from '../components/VideoView'
import { Grid, Typography, Slider, IconButton, FormControl, InputLabel, Select, MenuItem, Box, CircularProgress, Tooltip, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import moment from 'moment'
import LiveTvIcon from '@material-ui/icons/LiveTv';
import { green, yellow } from '@material-ui/core/colors';
import { callCam } from '../utils';
import { useCameraId, useSession } from './AutobahnSocket';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '100%',
    },
    paper: {
        position: 'absolute',
        width: 400,
        height: 100,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
    }
}));

const DownloadButton = (props) => {
    const vdo = props.vdo
    const rtcRef = props.rtcRef
    const [downloading, setDownloading] = useState(false)

    const handleDownloadClick = () => {
        console.log(downloading, rtcRef, vdo)
        if (downloading || !rtcRef.current || !vdo)
            return

        setDownloading(true)

        rtcRef.current.downloadVdo(vdo.id, vdo.filesize, vdo.filename, onDownloadFinish)
    }

    const onDownloadFinish = () => {
        setDownloading(false)
    }

    return downloading ? <CircularProgress /> : (
        <Tooltip title="Download" placement="top">
            <IconButton onClick={handleDownloadClick}>
                <GetAppIcon />
            </IconButton>
        </Tooltip>
    )
}

const PlayPauseIcon = (props) => {
    const play = props.play
    return play ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />
}


function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const DoorModal = (props) => {
    const [modalStyle] = useState(getModalStyle)
    const classes = useStyles()

    const open = props.open
    const onClose = props.onClose

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" >
            <div style={modalStyle} className={classes.paper}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{height: '100%'}}
                >
                    <Grid item>
                        <CheckCircleIcon style={{ color: green[500], fontSize: 40 }}/> 
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" style={{marginLeft: 16, marginBottom: 4}}>
                            The door has been opened!
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Modal>
    )
}

export default function VideoViewWithControl(props) {

    const classes = useStyles()
    const session = useSession()
    const cameraId = useCameraId()
    const datetime = props.datetime

    const onVideoError = props.onVideoError
    const onVideoSuccess = props.onVideoSuccess

    const autoLive = props.autoLive !== undefined ? props.autoLive : true
    const rtcRef = useRef()
    const [relayEnable, setRelayEnable] = useState(false)
    const [vdo, setVdo] = useState()
    const [currentPosition, setCurrentPosition] = useState(0)
    const [timerRun, setTimerRun] = useState(false)
    const [rate, setRate] = useState(1)
    const [doorModalShow, setDoorModalShow] = useState(false)

    const fetch = async () => {
        const res = await callCam(session, cameraId, 'config.relay.get')
        if (!res || !res.ok)
            return
        setRelayEnable(res.enable)
    }
    const fetchCallback = useCallback(fetch, [cameraId])

    useEffect(() => {
        fetchCallback()
    }, [fetchCallback])

    // when rtc is ready play video at given time
    const onRtcReady = () => {
        playVideoAtDatetime()
    }

    // if datetime is change => play video at given time
    const onDatetimeChange = () => {
        playVideoAtDatetime()
    }
    useEffect(onDatetimeChange, [datetime])

    const playVideoAtDatetime = () => {
        if (!rtcRef.current || !datetime)
            return

        const asyncFn = async () => {
            const res = await rtcRef.current.playVideoDatetime(datetime, rate)
            if (!res || !res.ok) {
                console.error('Cannot play video at:', datetime)

                // revert back to live
                handleLiveClick()

                if (onVideoError)
                    onVideoError()
                return
            }
            setVdo(res)
            setCurrentPosition(res.position)
            setTimerRun(true)

            if (onVideoSuccess)
                onVideoSuccess()
        }
        asyncFn()
    }

    // move slider
    useEffect(() => {
        let interval = null;
        if (timerRun) {
            interval = setInterval(() => {
                setCurrentPosition(currentPosition => currentPosition + rate)
            }, 1000)
        } else if (!timerRun && currentPosition !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerRun, currentPosition, rate]);

    // title name
    let title = autoLive ? 'Live' : ''
    let marks = [
        { value: 0, label: '0' },
        { value: 1, label: '1' }
    ]
    if (vdo) {
        title = moment(vdo.startAt).format('ddd DD/MM/YY - HH:mm')
        marks = [
            { value: 0, label: moment(vdo.startAt).format("HH:mm") },
            { value: vdo.duration, label: moment(vdo.startAt).add(vdo.duration, 'seconds').format("HH:mm") }
        ]
    }

    // handle slider
    const formatSliderLabel = (v) => {
        return moment(vdo.startAt).add(v, 'seconds').format("HH:mm")
    }

    const handleSliderChange = (e, v) => {
        setCurrentPosition(v)
    }

    const handleSliderCommit = (e, v) => {
        if (!vdo)
            return

        let datetime = moment(vdo.startAt).add(v, 'seconds').toDate()

        const asyncFn = async () => {
            const res = await rtcRef.current.playVideoDatetime(datetime, rate)
            if (!res || !res.ok) {
                console.error('Cannot play video at:', datetime)
                return
            }
            setVdo(res)
            setCurrentPosition(res.position)
            setTimerRun(true)
        }
        asyncFn()
    }

    // handle play pause
    const handlePlayPauseClick = () => {
        setTimerRun(!timerRun)
        const asyncFn = async () => {
            const res = await rtcRef.current.pauseVideo()
            if (!res || !res.ok) {
                console.error('Cannot pause video at:', datetime)
                return
            }
            setCurrentPosition(res.position)
        }
        asyncFn()
    }

    const handleLiveClick = () => {
        rtcRef.current.stopVideo()
        setVdo()
        setCurrentPosition(0)
        setTimerRun(false)
        setRate(1)
    }

    const handleUnlockDoor = async () => {
        const payload = {
            'name': localStorage.getItem('username')
        }
        const res = await callCam(session, cameraId, 'relay.on', payload)
        if(!res)
            return

        // show modal here
        setDoorModalShow(true)
    }

    // handle next prev
    const handleNextPrevVdo = (offset) => {
        if (!vdo)
            return

        let vdoId = vdo.id + offset

        const asyncFn = async () => {
            const res = await rtcRef.current.playVideoId(vdoId, rate)
            if (!res || !res.ok) {
                console.error('Cannot play video at:', datetime)
                return
            }
            setVdo(res)
            setCurrentPosition(res.position)
            setTimerRun(true)
        }
        asyncFn()
    }

    // handle rate
    const handleRateChange = (e) => {
        let r = e.target.value
        setRate(r)

        let dt = moment(vdo.startAt).add(currentPosition, 'seconds').toDate()

        const asyncFn = async () => {
            const res = await rtcRef.current.playVideoDatetime(dt, r)
            if (!res || !res.ok) {
                console.error('Cannot play video at:', datetime)
                return
            }
            setVdo(res)
            setCurrentPosition(res.position)
            setTimerRun(true)
        }
        asyncFn()
    }

    // handle modal close
    const onCloseDoorModal = () => {
        setDoorModalShow(false)
    }

    return (
        <div className={classes.root}>

            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>


                {vdo && <div>
                    <Tooltip title="Go Live" placement="top">
                        <IconButton onClick={handleLiveClick} color="secondary">
                            <LiveTvIcon />
                        </IconButton>
                    </Tooltip>

                    <DownloadButton vdo={vdo} rtcRef={rtcRef}></DownloadButton>
                </div>}

                {!vdo && relayEnable && <div>
                    <Tooltip title="Unlock Door" placement="top">
                        <IconButton onClick={handleUnlockDoor} style={{ color: yellow[800] }}>
                            <OpenInBrowserIcon />
                        </IconButton>
                    </Tooltip>

                    <DoorModal open={doorModalShow} onClose={onCloseDoorModal}></DoorModal>
                </div>}
            </Grid>

            <VideoView rtcRef={rtcRef} onReadyCallback={onRtcReady} />

            {vdo && <div>

                <Slider
                    defaultValue={0}
                    valueLabelDisplay="auto"
                    max={vdo.duration}
                    marks={marks}
                    valueLabelFormat={formatSliderLabel}
                    value={currentPosition}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderCommit}
                />

                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Tooltip title="Previous Video" placement="bottom">
                            <IconButton onClick={() => handleNextPrevVdo(-1)}>
                                <SkipPreviousIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item>
                        <Box>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel>Speed</InputLabel>
                                <Select
                                    value={rate}
                                    onChange={handleRateChange}
                                    label="Speed"
                                >
                                    <MenuItem value={-8}>-x8</MenuItem>
                                    <MenuItem value={-4}>-x4</MenuItem>
                                    <MenuItem value={-2}>-x2</MenuItem>
                                    <MenuItem value={-1}>-x1</MenuItem>
                                    <MenuItem value={1}>x1</MenuItem>
                                    <MenuItem value={2}>x2</MenuItem>
                                    <MenuItem value={4}>x4</MenuItem>
                                    <MenuItem value={8}>x8</MenuItem>
                                </Select>
                            </FormControl>

                            <IconButton onClick={handlePlayPauseClick}>
                                <PlayPauseIcon play={timerRun} />
                            </IconButton>
                        </Box>
                    </Grid>

                    <Grid item>
                        <Tooltip title="Next Video" placement="bottom">
                            <IconButton onClick={() => handleNextPrevVdo(1)}>
                                <SkipNextIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>}
        </div>
    );
}
