import React, { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import qs from 'query-string'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Chip, CircularProgress } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { ArrowBack, ArrowForward } from '@material-ui/icons'

import { useCameraId, useSession } from '../components/AutobahnSocket'
import { SmartCamContext } from '../components/SmartCamContext'
import FaceTimelineChart from '../components/FaceTimelineChart'
import FaceInfiniteScroll from '../components/FaceInfiniteScroll'
import { callCam } from '../utils'
import useQueryString, { momentParser } from '../useQueryString'


const useStyles = makeStyles((theme) => ({
    facepaper: {
        padding: theme.spacing(2),
    },
    divider: {
        padding: theme.spacing(1)
    }
}))

export default function FaceTimeline(props) {
    // global
    const classes = useStyles()
    const history = useHistory()
    const cameraId = useCameraId()
    const session = useSession()
    const smartCamContext = useRef(useContext(SmartCamContext))

    // passed down props
    const filter = props.filter
    const initToDate = filter && filter.toDate ? filter.toDate : moment().add(6 - moment().day(), 'days').endOf('day')  // coming saturday

    // qs
    const toDateToQs = (value) => value ? value.format() : value
    const toDateFromQs = (stringValue) => {
        if(!stringValue || stringValue.length === 0)
            return stringValue
        const d = moment(stringValue)
        return d.add(6 - d.day(), 'days').endOf('day')  // coming saturday
    }
    const toDateParser = { toQs: toDateToQs, fromQs: toDateFromQs }
    const [resolution, setResolution, restoreResolution] = useQueryString('resolution', 'Week')    // 'Day' or 'Week'
    const [toDate, setToDate, restoreToDate] = useQueryString('toDate', initToDate, toDateParser)
    const [selectedDate, setSelectedDate, restoreSelectedDate] = useQueryString('selectedDate', null, momentParser)

    // register back button
    window.onpopstate = () => {
        restoreResolution()
        restoreToDate()
        restoreSelectedDate()
    }

    // local
    const fromDate = filter && filter.fromDate ? filter.fromDate : resolution === 'Week' ? moment(toDate).subtract(6, 'days').startOf('day') : moment(toDate).startOf('day')
    const [loading, setLoading] = useState(false)
    const [chartData, setChartData] = useState([])
    const [infiniteScrollData, setInfiniteScrollData] = useState()

    useEffect(() => {
        smartCamContext.current.setTitle('Face Timeline')
        return () => {
            window.onpopstate = null
        }
    }, [])

    const buildInfScrollData = (chartDat, date = null) => {
        if(date){
            const data = chartDat.find(d => d.date === date.format())
            if(data && data.labelInfo)
                return data.labelInfo
        }
        const infiniteScrollDat = chartDat.reduce((acc, value) => {
            const labelInfo = value.labelInfo
            for(const labelId in labelInfo){
                if (!(labelId in acc) || acc[labelId] < labelInfo[labelId])
                    acc[labelId] = labelInfo[labelId]
            }
            return acc
        }, {})
        return infiniteScrollDat
    }

    // fetch data when resolution or fromDate changes
    const fetch = async () => {
        if(!resolution || !toDate)
            return
        // clear old states
        setInfiniteScrollData()
        setChartData()
        setLoading(true)

        // collect chartData
        let currentDate = fromDate
        const incUnit = resolution === 'Week' ? 'days' : 'hours'
        const chartDat = []
        while (currentDate < toDate) {

            // get payload
            const toTime = moment(currentDate).add(1, incUnit)
            const payload = {
                fromTime: currentDate.format(),
                toTime: toTime.format()
            }
            if (filter) {
                payload.keyword = filter.keyword
                payload.tagId = filter.tagId
                payload.image = filter.image
            }

            // call api
            const res = await callCam(session, cameraId, 'track.findbetweentime', payload)
            if (!res) {
                setLoading(false)
                return
            }

            // parse result => use the last trackId for each labelId
            const labelInfo = res.tracks.reduce((acc, value) => {
                const trackId = value.trackId
                const labelId = value.labelId
                const name = value.name
                if(!(labelId in acc) || acc[labelId] < trackId)
                    acc[labelId] = {trackId: trackId, name: name}
                return acc
            }, {})

            // collect in list
            chartDat.push({
                date: currentDate.format(),
                labelInfo: labelInfo,
                countVisitor: Object.keys(labelInfo).length,
            })

            currentDate = toTime
        }
        setChartData(chartDat)

        // build infiniteScrollData from chartData
        setInfiniteScrollData(buildInfScrollData(chartDat, selectedDate))

        setLoading(false)
    }
    const fetchCallback = useCallback(fetch, [resolution, toDate, session, cameraId, filter])
    useEffect(() => {
        fetchCallback()
    }, [fetchCallback])

    // user clicks
    const handleResolutionChange = (resolution) => {
        setSelectedDate()
        setResolution(resolution)

        // set toDate as coming saturday of current toDate
        setToDate(toDate.add(6 - toDate.day(), 'days').endOf('day'))
    }

    const handleArrowClick = (dir) => {
        setSelectedDate()
        let dt = resolution === 'Day' ? 1 : 7
        dt *= dir
        let newToDate = moment(toDate).add(dt, 'days')
        setToDate(newToDate)
    }

    const handleChartBar = (date) => {
        if(!date)
            return

        date = moment(date)

        if(resolution === 'Week')
        {
            // set toDate
            const endOfDate = date.endOf('day')
            if(!endOfDate.endOf('day').isSame(toDate, 'second')) // query string does not contains millisecond, so need isSame(x, 'second')
                setToDate(endOfDate)

            // show all infinite scroll data
            setSelectedDate()

            // set resolution to Day
            setResolution('Day')
        }
        else
        {
            // set selected date
            const newSelectedDate = selectedDate && selectedDate.format() === date.format() ? null : date   // handle deselect
            setSelectedDate(newSelectedDate)

            // rebuild infinite scroll data
            const newInfScrollData = buildInfScrollData(chartData, newSelectedDate)
            setInfiniteScrollData(newInfScrollData)
        }
    }

    const handleCardClick = (labelId) => {
        const labelIdStr = qs.stringify({'labelId': labelId})
        const toDateStr = qs.stringify({'toDate': toDate.format()})
        const resolutionStr = qs.stringify({'resolution': resolution})
        history.push(`/home/facehistory?${labelIdStr}&${toDateStr}&${resolutionStr}`)
    }

    const totalVisitor = infiniteScrollData ? Object.keys(infiniteScrollData).length : 0

    return (
        <React.Fragment>

            <Paper className={classes.facepaper}>

                <Grid container direction="row" justify="space-between" alignItems="center" >
                    <Typography variant="h4" component="h4" gutterBottom>
                        {resolution === 'Week' &&
                            <React.Fragment>
                                {fromDate.format('ddd D MMM')} - {toDate.format('ddd D MMM')}
                            </React.Fragment>
                        }
                        {resolution === 'Day' &&
                            <React.Fragment>
                                {toDate.format('ddd D MMM')}
                            </React.Fragment>
                        }
                    </Typography>

                    {resolution === 'Day' &&
                        <Grid item>
                            <Chip label='Week View' onClick={() => handleResolutionChange('Week')} color='primary' />
                        </Grid>
                    }

                    {!filter &&
                        <Grid item>
                            <IconButton onClick={() => handleArrowClick(-1)} ><ArrowBack /></IconButton>
                            <IconButton onClick={() => handleArrowClick(1)} ><ArrowForward /></IconButton>
                        </Grid>
                    }

                </Grid>

                <Grid item style={{ height: '200px', width: '100%' }}>
                    {loading ?
                        <CircularProgress style={{ marginLeft: '50%' }} />
                        :
                        <FaceTimelineChart data={chartData} onBarClick={handleChartBar} resolution={resolution} yLabel="Count (people)" selectedDate={selectedDate}/>
                    }
                </Grid>

            </Paper>

            <div className={classes.divider}></div>

            <Paper className={classes.facepaper} style={{ maxHeight: '100%', overflow: 'auto' }}>
                <Typography variant="h4" component="h4" gutterBottom>
                    {!selectedDate ?
                        <div>
                            {resolution === 'Week' &&
                                <div>
                                    {totalVisitor} Visitors
                                <Typography variant="h6" component="h6">
                                        {fromDate.format('ddd D MMM')} - {toDate.format('ddd D MMM')}
                                    </Typography>
                                </div>
                            }
                            {resolution === 'Day' &&
                                <div>
                                    {totalVisitor} Visitors
                                <Typography variant="h6" component="h6">
                                        {toDate.format('ddd D MMM')}
                                    </Typography>
                                </div>
                            }
                        </div>
                        :
                        <div>
                            {totalVisitor} Visitors
                            <Typography variant="h6" component="h6">
                                {moment(selectedDate).format('ddd D MMM')}
                            </Typography>
                        </div>
                    }
                </Typography>
                {infiniteScrollData &&
                    <FaceInfiniteScroll data={infiniteScrollData} onCardClicked={handleCardClick} />
                }
            </Paper>
        </React.Fragment>
    )
}
