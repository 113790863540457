import React, { useCallback, useEffect, useState } from 'react';
import TagAutocomplete from './TagAutocomplete';
import { useCameraId, useSession } from './AutobahnSocket';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Typography,  Chip } from '@material-ui/core';
import { capitalizeFirstLetter } from '../utils';

import { callCam } from '../utils'

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '90%',
        maxWidth: 800,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

export default function EditFaceModal({ labelId, open, onClose }) {
    const session = useSession()
    const classes = useStyles()
    const cameraId = useCameraId()

    const [modalStyle] = useState(getModalStyle);
    const [personInfo, setPersonInfo] = useState()


    const handleDeleteTag = async (tagId) => {
        if (!open || !labelId)
            return
        const payload = {
            labelId: labelId,
            tagId: tagId
        }
        const res = await callCam(session, cameraId, 'label.deletetag', payload)
        if (!res || !res.ok) {
            console.log('ERROR label deletetag')
        }
        await fetchFace()
    }

    async function fetchFace() {
        if (!labelId) {
            return
        }
        let payload = {
            id: labelId
        }
        const res = await callCam(session, cameraId, 'label.findbyid', payload)
        if (!res || !res.ok) {
            console.log('ERROR: Failed to find label by id')
            return
        }
        setPersonInfo(res.label)
    }
    const fetchFaceCallback = useCallback(fetchFace, [session, cameraId, labelId])

    useEffect(()=> {
        fetchFaceCallback()
    }, [fetchFaceCallback])

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Typography variant="h3" gutterBottom>
                Edit tag for {personInfo && "\"" + capitalizeFirstLetter(personInfo.name) + "\""}
            </Typography>
            <Typography variant="h6" gutterBottom>
                Tag
            </Typography>
            {personInfo &&
                personInfo.tags.length > 0 ?
                personInfo.tags.map(tag => {
                    return (
                        <Chip className={classes.tag} label={tag.name} key={tag.id} onDelete={() => handleDeleteTag(tag.id)} />
                    )
                })
                : 'No tags'
            }
            <br /><br />
            {cameraId && labelId && <TagAutocomplete cameraId={cameraId} labelId={labelId} onTagUpdate={fetchFace} />}

        </div>
    );

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>

    );
}
