import 'date-fns';
import moment from 'moment'
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export default function TimecardSetting(props) {

    const fromDate = props.fromDate
    const toDate = props.toDate
    const onChange = props.onChange

    const handleFromDateChange = (date) => {
        let newDate = moment(date).startOf('day')
        if(newDate > toDate)
            newDate = moment(toDate).startOf('day')
        onChange(newDate, toDate)
    }

    const handleToDateChange = (date) => {
        let newDate = moment(date).endOf('day')
        if(newDate < fromDate)
            newDate = moment(fromDate).endOf('day')
        const endOfToday = moment().endOf('day')
        if(newDate > endOfToday)
            newDate = endOfToday
        onChange(fromDate, newDate)
    }
    
    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="fromDate"
                    label="From Date"
                    value={fromDate.format('YYYY-MM-DD')}
                    onChange={(date) => handleFromDateChange(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{width:'100%'}}
                />
                <KeyboardDatePicker
                    disableToolbar
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="toDate"
                    label="To Date"
                    value={toDate.format('YYYY-MM-DD')}
                    onChange={(date) => handleToDateChange(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{width:'100%'}}
                />
            </MuiPickersUtilsProvider>
        </div>
  );
}
