import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';

export default function NoCam() {

    return (
        <React.Fragment>
            <Alert severity="warning">
                <AlertTitle><strong>Your camera becomes offline</strong></AlertTitle>
                Please check the available cameras on the upper right dropdown list
            </Alert>
        </React.Fragment>
    )

}