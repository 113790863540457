import  React, { createContext, useState } from 'react';
export const SmartCamContext = createContext({  
    title: ''
  }); 


export default function SmartCamContextProvider ({children}) {
    const [context, setContext] = useState({
        title: '',
    })

    const setTitle = (newTitle) => {
        const newContext = {...context}
        
        document.title = newTitle
        newContext.title = newTitle
        setContext(newContext)
    }



    return (
        <SmartCamContext.Provider value={{...context, setTitle}}>
            {children}
        </SmartCamContext.Provider>
    )
}