import React, {useState, useEffect, useCallback} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import FacecardMiniTrack from '../components/FacecardMiniTrack';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    infiniteScroll: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
}));

export default function FaceInfiniteScroll(props) {
    const classes = useStyles()

    const data = props.data   // data[labeId] = [trackId, trackId, ...]
    const onCardClicked = props.onCardClicked

    const [hasMore, setHasMore] = useState(true)
    const [offset, setOffset] = useState(0)
    const [dataOnDisplay, setDataOnDisplay] = useState([])

    const batchSize = 30

    const filterFaces = (acc, offset) => {
        const labels = Object.keys(data)
        const slicedLabels = labels.slice(offset, offset + batchSize)
        const newData = slicedLabels.map( s => {
            return {
                labelId: s,
                trackId: data[s].trackId,
                name: data[s].name
            }
        })
        return acc.concat(newData)
    }
    const filterFacesCallback = useCallback(filterFaces, [data])

    useEffect(() => {
        const d = filterFacesCallback([], 0)    // get first batch of data and set
        setDataOnDisplay(d)
        setOffset(d.length)
        setHasMore(d.length < Object.keys(data).length)
    }, [data, filterFacesCallback])

    const loadMore = () => {
        const newFaces = filterFaces(dataOnDisplay, offset)
        setDataOnDisplay(newFaces)
        setOffset(newFaces.length)    
    }

    return (
        <React.Fragment>  
            <InfiniteScroll
                dataLength={dataOnDisplay.length}
                next={loadMore}
                hasMore={hasMore}
                className={classes.infiniteScroll}
                >
                { dataOnDisplay && dataOnDisplay.map( face => {
                    return (
                        <FacecardMiniTrack key={face.labelId} face={face} onClick={onCardClicked}/>
                    )
                })}
            </InfiniteScroll>
        </React.Fragment>
  );
}
