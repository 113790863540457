import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { useCameraId, useSession } from './AutobahnSocket';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography, Button } from '@material-ui/core';
import LabelAutocomplete from './LabelAutocomplete';
import {callCam} from '../utils'

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '90%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  detailPaper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%'
  },
}));

export default function AddFaceModal (props) {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const session = useSession()
    const cameraId = useCameraId()

    const trackIds = props.trackIds
    const open = props.open
    const onClose = props.onClose
    const onSubmit = props.onSubmit

    const [label, setLabel] = useState(null)
    const [trackInfo, setTrackInfo] = useState()

    const handleLabelChange = (label) => {
        setLabel(label)
    }

    async function fetchFace() {

        if (!trackIds || !cameraId)
            return

        let payload = {
            trackId: trackIds[0]
        }
        const res = await callCam(session, cameraId, 'track.findonebyid', payload)
        if (!res)
            return

        setTrackInfo(res.track)
    }
    const fetchFaceCallback = useCallback(fetchFace, [session, cameraId, trackIds])

    useEffect(() => {
        fetchFaceCallback()
    }, [fetchFaceCallback])

    const handleSubmit = () => {
        if (!label) 
            return

        async function submit() {
            const payload = {
                trackIds: trackIds,
                name: label.name
            }
            const res = await callCam(session, cameraId, 'track.updatelabel', payload)
            if (!res)
                return
            onSubmit(res.labelId)
            setLabel(null)
        }        
        submit()
    }

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Typography variant="h5" gutterBottom>
                Add this face
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    {trackInfo && 
                        <Paper className={classes.detailPaper}>
                            <img style={{width: '100%'}} src={trackInfo.jpgData} alt=""/> <br/> 
                        </Paper>
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Paper className={classes.detailPaper}>
                        <LabelAutocomplete onLabelChange={handleLabelChange}/>
                        {label &&
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                {label.type === 'old' ? 'Add to existing face' : 'Create new face'}
                            </Button>
                        }   
                        
                    </Paper>
                </Grid>
            </Grid>
            
        </div>
    );

  return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            {body}
        </Modal>

  );
}
