import React from 'react';


import Search from './Search';
import Statistic from './Statistic';
import Playback from './Playback';
import Setting from './Setting';
import Timecard from './Timecard';
import Waittime from './Waittime';
import FaceHistory from './FaceHistory';
import FaceTimeline from './FaceTimeline';
import Contacts from './Contacts'
import PrivateRoute from '../components/PrivateRoute';
import { useCameraId } from '../components/AutobahnSocket';
import { Redirect, Route } from 'react-router-dom';


export default function HomeRoute() {
    const cameraId = useCameraId()

    return cameraId === -1 ?
        <Route render={ () => (
            <Redirect to="/home" />
        )} />
    : (
        <React.Fragment>
            <PrivateRoute component={FaceTimeline} exact path="/home" />
            <PrivateRoute component={FaceTimeline} path="/home/faceTimeline" />

            <PrivateRoute component={Contacts} path="/home/contacts" />
            <PrivateRoute component={Search} path="/home/search" />
            <PrivateRoute component={FaceHistory} path="/home/faceHistory" />
            <PrivateRoute component={Statistic} path="/home/statistic" />
            <PrivateRoute component={Waittime} path="/home/waittime" />
            <PrivateRoute component={Timecard} path="/home/timecard" />
            <PrivateRoute component={Playback} path="/home/playback" />
            <PrivateRoute component={Setting} path="/home/setting" />
        </React.Fragment>
    )
}