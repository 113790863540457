/* eslint-disable no-use-before-define */
import React, { useCallback, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { useCameraId, useSession } from './AutobahnSocket';
import { callCam } from '../utils'

const filter = createFilterOptions()

export default function LabelAutocomplete({ onLabelChange }) {
    const session = useSession()
    const cameraId = useCameraId()
    const [labels, setLabels] = useState([])

    async function fetchLabel() {
        if(!cameraId)
            return

        const res = await callCam(session, cameraId, 'label.list')
        if (!res || !res.ok)
            return

        let newLabels = []
        res.labels.map(l => {
            if (l.name) {
                newLabels.push(l)
            }
            return l
        })
        setLabels(newLabels)
    }
    const fetchLabelCallback = useCallback(fetchLabel, [session, cameraId])

    useEffect(() => {
        fetchLabelCallback()
    }, [fetchLabelCallback])

    return (
        <React.Fragment>
            <Autocomplete
                onChange={(event, newValue) => {
                    if(!newValue)
                        return

                    if (newValue && newValue.inputValue) {
                        // new label
                        onLabelChange({
                            type: 'new',
                            name: newValue.inputValue
                        })
                    } else {
                        // old label
                        onLabelChange({
                            type: 'old',
                            name: newValue.name
                        })
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                        })
                    }

                    return filtered
                }}
                id="free-solo-dialog-demo"
                options={labels}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option
                    }
                    if (option.inputValue) {
                        return option.inputValue
                    }
                    return option.name
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(option) => option.name}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} label="Name" variant="outlined" />
                )}
            />

        </React.Fragment>
    )
}
