async function callApi(session, uri, payload) {
    try{
        let res = await session.call(uri, [payload])
        if(!res || !res.ok){
            console.error('ERROR: Failure was returned from API call:', uri, payload, res);
        }
        return res
    }
    catch(err){
        console.error('ERROR: API call failed:', uri, payload, err)
    }
    return null
}

export async function callServer(session, suffix, payload={}){
    let uri = ['server', suffix].join('.')
    return await callApi(session, uri, payload)
}

export async function callCam(session, camId, suffix, payload={}){
    let uri = ['camera-' + String(camId), suffix].join('.')
    let res = await callApi(session, uri, payload)
    if(!res || !res.ok){
        console.error('Failed to call:', uri, 'Result:', res)
        return null
    }
    return res
}

export function capitalizeFirstLetter(string) {
    string = String(string)
    if (!string || string.length === 0)
        return ''

    let c = string.charAt(0)
    if(!(c >= 'A' && c <= 'z'))
        return string

    return c.toUpperCase() + string.slice(1);
}