import 'date-fns';
import moment from 'moment'
import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function AnalyticSetting(props) {

    const toDate = props.toDate
    const onChange = props.onChange

    const handleToDateChange = (date) => {
        let newDate = moment(date).endOf('day')
        const endOfToday = moment().endOf('day')
        if(newDate > endOfToday)
            newDate = endOfToday
        onChange(newDate)
    }

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="fromDate"
                    label="7 days until"
                    value={toDate.format('YYYY-MM-DD')}
                    onChange={(date) => handleToDateChange(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    style={{width:'100%'}}
                />
            </MuiPickersUtilsProvider>
        </div>
  );
}
