import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';

import './App.css';
import './pages/Home'
import SmartCamContextProvider from './components/SmartCamContext';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme, CssBaseline } from '@material-ui/core';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0e659d'
        },
        secondary: {
            main: '#00796B',
        },
    },
    typography: {
        fontFamily: 'Source Sans Pro',
    },
});

function App() {
    return (
        <div className="App">
            <header className="App-header">

                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <BrowserRouter>
                        <Switch>
                            <Route component={Login} exact path="/" />
                            <PrivateRoute component={Logout} exact path="/logout" />
                            <SmartCamContextProvider>
                                <PrivateRoute component={Home} path="/home" />
                            </SmartCamContextProvider>
                        </Switch>
                    </BrowserRouter>
                </ThemeProvider>

            </header>
        </div>
    );
}

export default App;
