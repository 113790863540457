import React, { useState, useEffect, useCallback } from 'react';
import { useCameraId, useSession } from './AutobahnSocket';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, ButtonBase } from '@material-ui/core';
import { callCam, capitalizeFirstLetter } from '../utils';
import lightGreen from '@material-ui/core/colors/lightGreen';
import blueGrey from '@material-ui/core/colors/blueGrey';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 200,
        margin: theme.spacing(1)
    },
    media: {
        height: 0,
        paddingTop: '75%', // 16:9
    },
    cardAction: {
        textAlign: 'initial'
    },
}));


const UnknownCard = (props) => {
    const classes = useStyles()
    const img = props.img

    return (
        <Card className={classes.root}>
            <Box borderLeft={4} borderColor={blueGrey[500]}>
                {img && <CardMedia
                        className={classes.media}
                        image={img}
                    />
                }
                <CardContent style={{ padding: '0.5rem' }}>
                    <Typography variant="body1" color="textSecondary">
                        Unknown
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    )
}

const KnownCard = (props) => {
    const classes = useStyles()
    const name = props.name
    const img = props.img

    return (
        <Card className={classes.root}>
            <Box borderLeft={4} borderColor={lightGreen[500]}>
                {img && <CardMedia
                        className={classes.media}
                        image={img}
                    />
                }
                <CardContent style={{ padding: '0.5rem' }}>
                    <Typography variant="body2" color="textSecondary">
                        {name}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    )
}


export default function FacecardMiniTrack(props) {
    const classes = useStyles()
    const session = useSession()
    const cameraId = useCameraId()

    const trackId = props.face.trackId
    const labelId = props.face.labelId
    const name = capitalizeFirstLetter(props.face.name)
    const onClick = props.onClick

    const [img, setImg] = useState()


    const fetchFace = async () => {
        if (!trackId)
            return

        // get jpg data of a track
        const payload = {
            trackId: trackId
        }
        const res = await callCam(session, cameraId, 'track.findonebyid', payload)
        if (!res) {
            console.log('ERROR track.findonebyid')
            return
        }
        setImg(res.track.jpgData)
    }
    const fetchFaceCallback = useCallback(fetchFace, [session]) // we don't need cameraId here

    useEffect(() => {
        fetchFaceCallback()
    }, [fetchFaceCallback])

    return (
        <ButtonBase className={classes.cardAction} onClick={() => onClick(labelId)}>
            {name ? <KnownCard img={img} name={name} /> : <UnknownCard img={img} />}
        </ButtonBase>
    );
}
