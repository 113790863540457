import moment from 'moment'
import { callCam, callServer } from '../src/utils'

const STATUS_CODE = {
    INIT: 0,
    WAITING_REMOTE_SDP: 2,
    RECEIVED_REMOTE_STREAM: 3,
    CREATING_ANSWER: 4,
    SENDING_SDP: 5,
    CREATING_DATA_CHANNEL: 6,
    READY: 7,

    FAIL_SETTING_REMOTE_SDP: -2,
    FAIL_CREATING_ANSWER: -3,
    FAIL_SETTING_LOCAL_SDP: -4,

    FAIL_ADDING_ICE: -5,

    RTC_CLOSED: -10
}

const codeToString = (code) => {
    switch (code) {
        case (STATUS_CODE.INIT): return "Initializing..."
        case (STATUS_CODE.WAITING_REMOTE_SDP): return "Waiting for SDP..."
        case (STATUS_CODE.RECEIVED_REMOTE_STREAM): return "Received incoming stream..."
        case (STATUS_CODE.CREATING_ANSWER): return "Creating SDP answer..."
        case (STATUS_CODE.SENDING_SDP): return "Sending SDP answer..."
        case (STATUS_CODE.CREATING_DATA_CHANNEL): return "Creating data channel..."
        case (STATUS_CODE.READY): return "RTC Ready"

        case (STATUS_CODE.FAIL_SETTING_REMOTE_SDP): return "Failed to set remote SDP"
        case (STATUS_CODE.FAIL_ADDING_ICE): return "Failed to add ICE candidate"
        case (STATUS_CODE.FAIL_CREATING_ANSWER): return "Failed to create SDP answer"
        case (STATUS_CODE.FAIL_SETTING_LOCAL_SDP): return "Failed to set local SDP"

        case (STATUS_CODE.RTC_CLOSED): return "RTC was closed"

        default: return "UNKOWN CODE"
    }
}

const STATUS_STR = {}
for (var k in STATUS_CODE) {
    let code = STATUS_CODE[k]
    STATUS_STR[code] = codeToString(code)
}

const createRtc = async (videoElement, session, camId, onReady=null, onDcMsg=null, verbose=false) => {

    const logAndSetStatus = (s) => {
        if(!verbose)
            return
        console.log(s, "[" + camId + "]", STATUS_STR[s])
    }

    // create RTCPeerConnection and setup callbacks
    let credential = await callServer(session, 'coturn.credential.get')
    let rtcConfig = {
        iceServers: [{
            urls: "turn:cam.lumio3d.com:3478",
            username: credential.username,
            credential: credential.password
        }]
    }

    let rtc = new RTCPeerConnection(rtcConfig)
    rtc.peerId = localStorage.getItem('username') + '-' + session._id
    rtc.session = session
    rtc.camId = camId
    rtc.onDcMsg = onDcMsg   // callback on datachannel string

    // when we received ice candidates from stun/turn server forward them to other peer
    rtc.onicecandidate = (event) => {
        if (event.candidate == null) {
            console.log("[" + camId + "]", "ICE Candidate done!")
            return
        }
        let msg = {
            'peerId': rtc.peerId,
            'ice': event.candidate
        }
        callCam(session, camId, 'live.ice', msg)
    }

    // always use track 0 from event
    rtc.ontrack = (event) => {
        logAndSetStatus(STATUS_CODE.RECEIVED_REMOTE_STREAM)
        videoElement.srcObject = event.streams[0]
    }

    rtc.downloadVdo = async (vdoId, filesize, filename, onDownloadFinish) => {
        rtc.filesize = filesize
        rtc.filename = filename
        rtc.downloadedData = []
        rtc.bytesReceived = 0
        rtc.onDownloadFinish = onDownloadFinish

        console.log("Start downloading vdo:", vdoId, filesize, filename)
        let payload = {
            peerId: rtc.peerId,
            vdoId: vdoId
        }
        callCam(session, camId, 'video.download', payload)
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    // DATA CHANNEL
    rtc.ondatachannel = (event) => {

        logAndSetStatus(STATUS_CODE.CREATING_DATA_CHANNEL)

        const handleDataChannelOpen = (event) => {
            console.log("==================================================== DC OPEN", camId)
            rtc.dataChannel = event.target
            logAndSetStatus(STATUS_CODE.READY)

            if(onReady)
                onReady()
        }

        const onReceiveBinary = (data) => {
            rtc.bytesReceived += data.byteLength
            rtc.downloadedData.push(data)
            
            if(rtc.bytesReceived === rtc.filesize){
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = URL.createObjectURL(new Blob(rtc.downloadedData, {type: 'video/mp4'}))
                link.download =  rtc.filename
                link.click()

                console.log('really download finish')

                if(rtc.onDownloadFinish)
                    rtc.onDownloadFinish()
            }
        }

        const onReceiveString = (data) => {
            try{
                data = JSON.parse(data)
            }
            catch(err){
                console.error(err)
                return
            }

            if(rtc.onDcMsg)
                rtc.onDcMsg(data)
            
        }

        const handleDataChannelMessageReceived = (event) => {

            let data = event.data
            let isBinary = typeof data === 'object' && data instanceof ArrayBuffer
            let isString = typeof data === 'string' || data instanceof String
            if(isBinary)
                return onReceiveBinary(data)
            else if(isString)
                return onReceiveString(data)
        }

        const handleDataChannelError = (error) => {
            console.log("dataChannel.OnError:", error)
        }

        const handleDataChannelClose = (event) => {
            console.log("==================================================== DC CLOSE", camId)
        }

        let dataChannel = event.channel
        dataChannel.onopen = handleDataChannelOpen
        dataChannel.onmessage = handleDataChannelMessageReceived
        dataChannel.onerror = handleDataChannelError
        dataChannel.onclose = handleDataChannelClose

        if(!verbose)
            return

        const asyncFn = async () => {
            const e = await rtc.getStats()
            let localCandidate = ''
            let remoteCandidate = ''
            e.forEach(ee => {
                if (ee.type === 'candidate-pair' && ee.state === 'succeeded') {
                    localCandidate = ee.localCandidateId
                    remoteCandidate = ee.remoteCandidateId
                }
            })
            console.log("Selected Pair:")
            e.forEach(ee => {
                // console.log(ee)
                if (ee.type === 'local-candidate' && ee.id === localCandidate)
                    console.log('local candidate:', ee.candidateType, ee.ip + ":" + ee.port, ee.id)
                else if (ee.type === 'remote-candidate' && ee.id === remoteCandidate)
                    console.log('remote candidate:', ee.candidateType, ee.ip + ":" + ee.port, ee.id)
            })
        }
        asyncFn()
    }
    // DATA CHANNEL
    ///////////////////////////////////////////////////////////////////////////////////////

    rtc.onSdp = async (detail) => {
        if(rtc.signalingState === 'closed')
            return

        let sdp = detail[0]
    
        // set the received sdp as remote sdp
        if (sdp.type !== "offer") {
            console.error("SDP is not offer")
            return
        }

        // set remove sdp
        try {
            await rtc.setRemoteDescription(sdp)
        }catch(e){
            console.error(e)
            logAndSetStatus(STATUS_CODE.FAIL_SETTING_REMOTE_SDP)
            return
        }
        
        // create answer
        let desc = null
        try{
            logAndSetStatus(STATUS_CODE.CREATING_ANSWER)
            desc = await rtc.createAnswer()
        } catch (e) {
            console.error(e)
            logAndSetStatus(STATUS_CODE.FAIL_CREATING_ANSWER)
            return
        }

        // use this answer as local sdp
        try{
            await rtc.setLocalDescription(desc)
        } catch (e) {
            console.log(e)
            logAndSetStatus(STATUS_CODE.FAIL_SETTING_LOCAL_SDP)
            return
        }

        // send local sdp to remote
        logAndSetStatus(STATUS_CODE.SENDING_SDP)
        let msg = {
            'peerId': rtc.peerId,
            'sdp': rtc.localDescription
        }
        callCam(session, camId, 'live.sdp', msg)
    }

    rtc.onIce = (detail) => {
        if(rtc.signalingState === 'closed')
            return

        let ice = detail[0]

        if (!rtc) {
            console.error("Received ice candidate from camera but rtc is null", ice)
            return
        }
        rtc.addIceCandidate(
            new RTCIceCandidate(ice)
        ).catch(e => {
            console.error(e)
            logAndSetStatus(STATUS_CODE.FAIL_ADDING_ICE)
        })
    }

    rtc.playVideoDatetime = async (datetime, rate=1) => {
        let datetimeIso = moment(datetime).format()
        let payload = {
            peerId: rtc.peerId,
            datetime: datetimeIso,
            rate: rate
        }
        return await callCam(session, camId, 'video.play.datetime', payload)
    }

    rtc.stopVideo = async () => {
        let payload = {
            peerId: rtc.peerId,
        }
        return await callCam(session, camId, 'video.stop', payload)
    }

    rtc.playVideoId = async (vdoId, rate=1) => {
        let payload = {
            peerId: rtc.peerId,
            vdoId: vdoId,
            rate: rate
        }
        return await callCam(session, camId, 'video.play.id', payload)
    }

    rtc.pauseVideo = async () => {
        let payload = {
            peerId: rtc.peerId
        }
        return await callCam(session, camId, 'video.pause', payload)
    }

    // setup rtc callback and session
    const subscribeSdp = async () => {
        await session.subscribe(rtc.peerId + '.camera-' + camId + '.live.sdp', rtc.onSdp)
    }
    const subscribeIce = async () => {
        await session.subscribe(rtc.peerId + '.camera-' + camId + '.live.ice', rtc.onIce)
    }
    subscribeSdp()
    subscribeIce()

    // tell camera that I am ready
    const callLiveStart = async () => {
        let payload = { 'peerId': rtc.peerId }
        await callCam(session, camId, 'live.start', payload)
    }
    callLiveStart()
    
    return new Promise(resolve => resolve(rtc))
}

const destroyRtc = (rtc) => {
    if(!rtc)
        return

    const session = rtc.session
    const peerId = rtc.peerId
    const camId = rtc.camId

    // tell camera to stop streaming
    const callLiveStop = async () => {
        let payload = { 'peerId': peerId }
        await callCam(session, camId, 'live.end', payload)
    }
    callLiveStop()

    rtc.close()
}

export const Rtc = {
    'createRtc': createRtc,
    'destroyRtc': destroyRtc
}
