import React, { useState} from 'react';
import { useCameraId, useSession } from '../components/AutobahnSocket';
import { callCam } from '../utils'
import {Snackbar, CircularProgress, Button, makeStyles, Typography, Toolbar, IconButton, Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import {Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {Collapse, FormHelperText, FormControl, TextField} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';
import ConfirmModal from '../components/ConfirmModal'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    title: {
        flex: '1 1 100%',
    },
  }));

const EnhancedToolbar = (props) => {
    const classes = useToolbarStyles();
    const updateModal = props.updateModal

    return (
        <Toolbar className={classes.root}>
            <Typography variant="h6" id="tableTitle" component="div">
                Wifi Config
            </Typography>
            <IconButton onClick={updateModal} align='right'>
                <RefreshIcon />
            </IconButton>
        </Toolbar>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function WifiModal(props) {
    const session = useSession()
    const camId = useCameraId()
    const [wifis, setWifis] = useState([])
    const classes = useStyles();
    const [openOkBar, setOpenOkBar] = useState(false)
    const [openWarnBar, setOpenWarmBar] = useState(false)
    const [openErrorBar, setOpenErrorBar] = useState(false)
    const [isReloading, setIsReloading] = useState(false)

    const open = props.open
    const onClose = props.onClose

    const handleOkBarOpen = () => { setOpenOkBar(true) }
    const handleOkBarClose = () => { setOpenOkBar(false) }
    const handleWarnBarOpen = () => { setOpenWarmBar(true) }
    const handleWarnBarClose = () => { setOpenWarmBar(false) }
    const handleErrorBarOpen = () => { setOpenErrorBar(true) }
    const handleErrorBarClose = () => { setOpenErrorBar(false) }

    const updateModal = () => {
        setIsReloading(true)
        const asyncFn = async () => {
            const res = await callCam(session, camId, 'config.wifi.list')
            if(!res || !res.ok)
                return
            setWifis(res.wifi)
            setIsReloading(false)
        }
        asyncFn()
    }

    const WifiButton = (props) => {
        const activation = props.activation
        const ssid = props.ssid
        const [openConnect, setOpenConnect] = useState(false)
        const [openForget, setOpenForget] = useState(false)

        const ConnectWifiDialog = (props) => {
            const open = props.open
            const onClose = props.onClose
            const [pwd, setPwd] = useState("")
            const [loading, setLoading] = useState(false)
            const [isError, setIsError] = useState(false)

            const onConfirmConnect = () => {
                const camId = props.camId
                let payload = {
                    ssid: ssid,
                    pwd: pwd
                }
                setLoading(true)
                
                const asyncFn = async () => {
                    const res = await callCam(session, camId, 'config.wifi.connectnew', payload)
                    // set status
                    setLoading(false)
                    if(!res || !res.ok)
                        setIsError(true)
                    else{
                        updateModal()
                        handleOkBarOpen()
                        return;
                    }
                    
                    
                    // show status for 15 sec
                    setTimeout(() => { 
                        handleWarnBarOpen()
                    }, 15000)
                    updateModal()
                }
                asyncFn()
            }

            return (
                <Dialog
                    open={open}
                    onClose={onClose}
                >
                    <DialogTitle>SSID: {ssid}</DialogTitle>
                    <DialogContent>
                        <FormControl error>
                            <TextField
                            label="password"
                            type="password"
                            variant="outlined"
                            value={pwd}
                            onChange={ (e) => setPwd(e.target.value) }
                            disabled={loading}
                            error={isError}
                            />
                            <Collapse in={isError}>
                                <FormHelperText id="component-error-text">Is your password correct?</FormHelperText>
                            </Collapse>
                        </FormControl>

                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <div className={classes.wrapper}>
                            <Button onClick={onConfirmConnect} color="primary" disabled={loading}>
                                Connect
                            </Button>
                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </DialogActions>
                </Dialog>
            );
        }

        const handleConnectOpen = () => { setOpenConnect(true) }
        const handleConnectClose = () => { setOpenConnect(false) }
        const handleForgetOpen = () => { setOpenForget(true) }
        const handleForgetClose = () => { setOpenForget(false) }

        const onConfirmForget = () => {
            const camId = props.camId
            let payload = {
                ssid: ssid
            }
            const asyncFn = async () => {
                const res = await callCam(session, camId, 'config.wifi.forget', payload)
                // set status
                if(!res || !res.ok)
                    handleErrorBarOpen()
                else{
                    handleOkBarOpen()
                    updateModal()
                    return;
                }
                    
    
                // show status for 3 sec
                setTimeout(() => { 
                    handleWarnBarOpen()
                }, 30000)
                updateModal()
            }
            asyncFn()
        }

        const onConfirmConnectUsed = () => {
            const camId = props.camId
            let payload = {
                ssid: ssid
            }
            setIsReloading(true)
            const asyncFn = async () => {
                const res = await callCam(session, camId, 'config.wifi.connectused', payload)
                // set status
                if(!res || !res.ok)
                    handleErrorBarOpen()
                else{
                    handleOkBarOpen()
                    updateModal()
                    return;
                }
    
                // show status for 3 sec
                setTimeout(() => { 
                    handleWarnBarOpen()
                }, 30000)
                updateModal()
            }
            asyncFn()
        }


        if(activation === 'in-use')
            return (
            <TableCell align="right">
                <Button onClick={handleForgetOpen} variant="contained" color="secondary">Forget</Button>
                <ConfirmModal open={openForget} onClose={handleForgetClose} title="Forget Connection?" subtitle="This connect will be delete, Are you sure?." onConfirm={onConfirmForget}/>
            </TableCell>
            );
        else if(activation === 'used')
            return (
            <TableCell align="right">
                <Button onClick={onConfirmConnectUsed} variant="contained" color="primary">Connect</Button>
                <Button onClick={handleForgetOpen} variant="contained" color="secondary">Forget</Button>
                <ConfirmModal open={openForget} onClose={handleForgetClose} title="Forget Connection?" subtitle="This connect will be delete, Are you sure?." onConfirm={onConfirmForget}/>
            </TableCell>
            )
        else
            return (
            <TableCell align="right">
                <Button onClick={handleConnectOpen} variant="contained" color="primary">Connect</Button>
                <ConnectWifiDialog open={openConnect} onClose={handleConnectClose} {...props} />
            </TableCell>
            );

    }



    return (
        <div className={classes.root}>
            <Dialog
                keepMounted
                open={open}
                onClose={onClose}
                onEnter={updateModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                maxWidth="sm"
            >
                <Collapse in={!isReloading}>
                    <DialogContent>
                        <EnhancedToolbar updateModal={updateModal}/>
                        <TableContainer>
                            <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>SSID</TableCell>
                                    <TableCell align="right">Signal</TableCell>
                                    <TableCell align="right"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {wifis.map((wifi) => (
                                    <TableRow key={wifi.id}>
                                        <TableCell component="th" scope="row">
                                            {wifi.ssid}
                                        </TableCell>
                                        <TableCell align="right">{wifi.signal}</TableCell>
                                        <WifiButton activation={wifi.activation} ssid={wifi.ssid} camId={camId} />
                                    </TableRow>
                                ))}
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                </Collapse>
                <Collapse in={isReloading}>
                    <div style={{ padding: 20 }}>
                        <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        >
                            <CircularProgress  size={50} />
                        </Grid>
                    </div>
                </Collapse>
                <Snackbar open={openOkBar} autoHideDuration={6000} onClose={handleOkBarClose}>
                    <Alert onClose={handleOkBarClose} severity="success">
                    Success!!
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorBar} autoHideDuration={6000} onClose={handleErrorBarClose}>
                    <Alert onClose={handleOkBarClose} severity="error">
                    Error!!
                    </Alert>
                </Snackbar>
                <Snackbar open={openWarnBar} autoHideDuration={6000} onClose={handleWarnBarClose}>
                    <Alert onClose={handleOkBarClose} severity="warning">
                    Timeout!!
                    </Alert>
                </Snackbar>

            </Dialog>
        </div>
      );
}
