import React from 'react';
import {  Button, Dialog, Slide, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
export default function ConfirmModal (props) {
    
    const open = props.open
    const onClose = props.onClose

    const title = props.title || "Confirm?"
    const subtitle = props.subtitle || ""
    const onConfirm = props.onConfirm

    const handleConfirmClick = () => {
        if(onConfirm)
            onConfirm()
        onClose()
    }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
      >
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText >
            {subtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClick} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
