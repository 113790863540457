import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { useCameraId, useSession } from './AutobahnSocket'
import { callCam, capitalizeFirstLetter } from '../utils'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'
import lightGreen from '@material-ui/core/colors/lightGreen'
import blueGrey from '@material-ui/core/colors/blueGrey'
import { Box, Grid, IconButton, withStyles } from '@material-ui/core'
import { PersonAdd, PlayCircleFilledWhite } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    root: {
        width: 256,
        margin: theme.spacing(1)
    },
    media: {
        paddingTop: '75%'   // '56.25%', // 16:9
    },
}))

const ResizableIconButton = ({classes, size, ...props}) =>
    <IconButton className={classes[size]}
                {...props}/>

const styles = {
    large: {
        '& svg': {
            fontSize: 72
        }
    }
}

const BigIconButton = withStyles(styles)(ResizableIconButton)



export function FaceCardBase({trackIds, isKnownFace, trackInfo, validPeriod, addFace=null, onClick=null}){
    const classes = useStyles()

    const jpgData = trackInfo.jpgData
    const start = trackInfo.start
    const end = trackInfo.end
    const videoAvailable = validPeriod && validPeriod.begin && validPeriod.end && moment(validPeriod.begin) <= moment(start) && moment(validPeriod.end) >= moment(end)

    return (
        <Card className={classes.root}>
            <Box borderLeft={4} borderColor={isKnownFace ? lightGreen[500] : blueGrey[500]}>
                {jpgData && <CardMedia
                        className={classes.media}
                        image={jpgData}
                        title={isKnownFace ? capitalizeFirstLetter(trackInfo.id) : 'Unknown Face'}
                    />
                }
                <CardContent style={{padding:'16px'}}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body1" color="textSecondary">
                                {moment(start).format('ddd D MMM')}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {moment(start).format("HH:mm") + ' - ' + (end ? moment(end).format("HH:mm") : "Now" ) }
                            </Typography>
                        </Grid>
                        <Grid item>
                            {videoAvailable ?
                                <BigIconButton size='large' style={{padding:'0'}} onClick={() => onClick(trackInfo)}>
                                    <PlayCircleFilledWhite style={{ color: green[500] }}/>
                                </BigIconButton>
                            :
                                <NoVideoButton/>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                {!isKnownFace && addFace && <CardActions style={{paddingTop:'0px'}}>
                    <Button size="small" color="primary" startIcon={<PersonAdd />} onClick={() => addFace(trackIds)}>
                        Add face
                    </Button>
                </CardActions>}
            </Box>
        </Card>
    )
}

export function FacecardJpgOnly(props) {
    const cameraId = useCameraId()
    const session = useSession()
    const [trackInfo, setTrackInfo] = useState()

    const trackIds = props.trackIds
    const isKnownFace = props.isKnownFace
    const start = props.start
    const end = props.end
    const validPeriod = props.validPeriod
    const addFace = props.addFace
    const onClick = props.onClick

    async function fetchFace() {

        if (!trackIds || !cameraId) {
            return
        }

        let payload = {
            trackId: trackIds[0]
        }
        
        // get track information
        let res = await callCam(session, cameraId, 'track.findonebyid', payload)
        if (!res) {
            console.log('ERROR track.findonebyid')
            return
        }
        setTrackInfo({
            'start': start,
            'end': end,
            'jpgData': res.track.jpgData,
            'id': trackIds[0]
        })
    }
    const fetchFaceCallback = useCallback(fetchFace, [session, cameraId, trackIds])

    useEffect(()=> {
        fetchFaceCallback()
    }, [fetchFaceCallback])

    return ( trackInfo ? <FaceCardBase trackIds={trackIds} isKnownFace={isKnownFace} trackInfo={trackInfo} validPeriod={validPeriod} addFace={addFace} onClick={onClick}></FaceCardBase> : null )
}

export default function Facecard({ trackId, isKnownFace, validPeriod, addFace=null, onClick=null}) {
    const cameraId = useCameraId()
    const session = useSession()
    const [trackInfo, setTrackInfo] = useState()

    async function fetchFace() {
        if (!trackId || !cameraId) {
            return
        }

        let payload = {
            trackId: trackId
        }
        
        // get track information
        let res = await callCam(session, cameraId, 'track.findonebyid', payload)
        if (!res || !res.ok) {
            console.log('ERROR track.findonebyid')
            return
        }
        res.track['id'] = trackId
        setTrackInfo(res.track)
    }
    const fetchFaceCallback = useCallback(fetchFace, [session, cameraId, trackId])

    useEffect(()=> {
        fetchFaceCallback()
    }, [fetchFaceCallback])

    return ( trackInfo ? <FaceCardBase trackId={trackId} isKnownFace={isKnownFace} trackInfo={trackInfo} validPeriod={validPeriod} addFace={addFace} onClick={onClick}></FaceCardBase> : null )
}


function NoVideoButton() {
    return (
        <div>
            <BigIconButton size='large' style={{padding:'0'}}>
                <PlayCircleFilledWhite disabled/>
            </BigIconButton>
            <Typography variant="caption" display="block" align="center">
                Expired
            </Typography>
        </div>
    )
}
