import React, { useState, createContext, useEffect, useCallback } from 'react'
import autobahn from 'autobahn-browser'
import { callServer } from '../utils'

const initialContext = {  
    session: null,
    cameras: [],
    cameraId: -1
}

export const SocketContext = createContext(initialContext)

export const useSession = () => {
    const context = React.useContext(SocketContext)
    if (context.session === undefined) {
        throw new Error('ERROR: NO SESSION')
    }
    return context.session
}

export const useWebsocket = () => {
    const context = React.useContext(SocketContext)
    if (context === undefined) {
      throw new Error('ERROR: NO SESSION')
    }
    return context
  }

export const useCameras = () => {
    const context = React.useContext(SocketContext)
    if (context === undefined) {
        throw new Error('ERROR: NO SESSION')
    }
    return context.cameras
}

export const useCameraId = () => {
    const context = React.useContext(SocketContext)
    if (context === undefined) {
        throw new Error('ERROR: NO SESSION')
    }
    return context.cameraId
}

export default function SocketProvider ({ children }) { 
    const [context, setContext] = useState(initialContext)
    const [subscriptionMsg, setSubscriptionMsg] = useState()

    const onSubscriptionMsg = () => {
        if(!context || !context.cameras || !subscriptionMsg)
            return

        const args = subscriptionMsg[0]
        const publication = subscriptionMsg[1]

        const camId = args[0].camId
        const topic = publication.topic
        const {cameras} = context
        for (let i=0; i < cameras.length; ++i) {
            if (cameras[i].id === camId) {
                if(topic.includes('camera.down')){
                    cameras[i].isOnline = false
                }
                else if(topic.includes('camera.up')){
                    cameras[i].isOnline = true
                }
                break
            }
        }

        // check current camera becomes offline
        let cameraId = context.cameraId
        if(topic.includes('camera.down') && cameraId === camId){
            console.log('camera down:', cameraId)
            cameraId = -1
        }

        setContext({...context, cameras: cameras, cameraId: cameraId})
        localStorage.setItem('cameraId', cameraId)
    }
    const onSubscriptionMsgCallback = useCallback(onSubscriptionMsg, [subscriptionMsg])
    useEffect(() => {
        onSubscriptionMsgCallback()
    }, [onSubscriptionMsgCallback])

    const subscribeUser = (args, _, publication) => {
        setSubscriptionMsg([args, publication])
    }

    useEffect(() => {
        if (!context || !context.session || context.cameras.length === 0)
            return

        // subscribe to user channel
        const email = localStorage.getItem('username')
        context.session.subscribe(email + '.', subscribeUser, { match: "prefix" })   // this '.' at the end is utmost important
    }, [context, context.session])

    const setCameraId = (newId) => {
        setContext({...context, cameraId: newId})
        localStorage.setItem('cameraId', newId)
    }

    const login = async (username, password) => {
        var connection = new autobahn.Connection({
            url: 'wss://cam.lumio3d.com:4433/ws',
            // url: 'ws://localhost:4433/ws',
            realm: 'smart_cam',
            authmethods: ["ticket"],
            authid: `user-${username}`,
            onchallenge: (session, method, extra) => {
                if (method === "ticket")
                    return password
                else
                    console.log("Invalid authmethod ", method)
            },
        })

        await connection.open()


        connection.onopen = async (session) => {
            console.log('connected sessionId:', session._id);

            // save token in localstorage
            let res = await callServer(session, 'token.generate')
            if (!res || !res.ok) {
                console.log('ERROR token generate')
                return
            }
            localStorage.setItem('username', `${username}`)
            localStorage.setItem('token', res.token)

            // get all cameras
            res = await callServer(session, 'camera.list', res.userId)
            if (!res || !res.ok) {
                console.log('ERROR camera list')
                return
            }
            const cameras = res.cameras
            
            // select default camera to the first online camera
            let cameraId = -1
            
            if (res.cameras && res.cameras.length > 0){

                // sort res.cameras by name
                res.cameras.sort((a,b) => {
                    const nameA = a.name.toUpperCase()
                    const nameB = b.name.toUpperCase()
                    return nameA < nameB ? -1 : 1
                })


                // set cameraId to the id saved in localstorage
                const savedCameraId = parseInt(localStorage.getItem('cameraId'))
                if (savedCameraId) {
                    const filteredCamera = cameras.filter(c => {
                        return c.isOnline && c.id === savedCameraId
                    })
                    if (filteredCamera.length === 1) {
                        cameraId = filteredCamera[0].id
                    }
                }

                // pick the first online camera if cameraId is still not set
                if (cameraId === -1) {
                    for (let i=0; i < cameras.length; ++i) {
                        if (cameras[i].isOnline) {
                            cameraId = cameras[i].id
                            break
                        }
                    }
                }
            }

            setContext({ ...context, session: session, cameras: res.cameras, cameraId: cameraId })
            localStorage.setItem('cameraId', cameraId)
        }
 
        connection.onclose = (msg ,details) => {
            if (details.reason === 'wamp.error.authentication_failed') {
                console.log('Crossbar authentication failed')
                localStorage.clear()
                setContext({ session: null })
            }
        }
    }
    const loginCallback = useCallback(login, [])

    return (
        <SocketContext.Provider value={{ ...context , login: loginCallback, setCameraId: setCameraId }}>
            {children}
        </SocketContext.Provider>
    );
}
