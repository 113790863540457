import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useCameraId, useSession } from './AutobahnSocket';
import Grid from '@material-ui/core/Grid';
import { Button, Dialog, DialogTitle, DialogActions, CircularProgress } from '@material-ui/core';
import LabelAutocomplete from './LabelAutocomplete';
import { callCam } from '../utils'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    detailPaper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        height: '100%'
    },
}));

export default function AddFaceJpgModal(props) {
    const classes = useStyles();
    const session = useSession()
    const cameraId = useCameraId()

    const [image, setImage] = useState(null)
    const [fileUrl, setFileUrl] = useState(null)
    const [label, setLabel] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const open = props.open
    const onClose = props.onClose
    const onSubmit = props.onSubmit


    const reader = new FileReader()
    reader.onload = () => {
        setImage(reader.result)
    }

    const handleLabelChange = (label) => {
        setLabel(label)
    }

    const handleSubmit = () => {
        if (!label || !image)
            return

        async function submit() {
            const payload = {
                image: image,
                name: label.name
            }
            setLoading(true)
            const res = await callCam(session, cameraId, 'label.addjpg', payload)
            setLoading(false)
            setIsError(!!!res)
            if (!res)
                return
            setLabel(null)
            onSubmit()
        }
        submit()
    }

    const handleFileChange = (e) => {
        const f = e.target.files[0]
        setFileUrl(URL.createObjectURL(f))
        reader.readAsDataURL(f)
    }

    const handleClose = () => {
        setImage(null)
        setFileUrl(null)
        setLabel(null)
        setLoading(false)
        setIsError(false)
        onClose()
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    {isError && <Alert severity="warning">
                        <AlertTitle><strong>Adding Face Fail!</strong></AlertTitle>
                    </Alert>}
                    {!isError && <React.Fragment>
                        Add Face
                    </React.Fragment>}
                </DialogTitle>
                {/* DialogContent has unwanted scrollbar */}
                <div style={{ padding: 20 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <img style={{ width: '100%' }} src={fileUrl} alt="" /> <br />
                        </Grid>
                        
                        <Grid item xs={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <input
                                        accept="image/jpeg"
                                        className={classes.input}
                                        style={{ display: 'none' }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="raised-button-file">
                                    <Button component="span">
                                        Upload jpg
                                    </Button>
                                    </label> 
                                </Grid>
                                <Grid item xs={12}>
                                    <LabelAutocomplete onLabelChange={handleLabelChange} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    {loading && <CircularProgress size={24} className={classes.loading} />}
                    {!loading && label &&
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            {label.type === 'old' ? 'Add to existing face' : 'Create new face'}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>

    );
}
