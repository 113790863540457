import React from 'react';

import { IconButton, Tooltip, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const LightToolTip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export default function MyTooltip(props) {
    const title = props.title

    return (
        <LightToolTip title={title}>
            <IconButton>
                <InfoIcon fontSize="small" />
            </IconButton>
        </LightToolTip>
    )
}