import React, { useState, useEffect, useCallback } from 'react'

import { useCameraId, useSession } from '../components/AutobahnSocket'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { Typography, Box, LinearProgress, Switch, InputAdornment, FormControl, InputLabel, Input, TextField, IconButton, CircularProgress, Select, MenuItem, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import SettingsIcon from '@material-ui/icons/Settings'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CancelIcon from '@material-ui/icons/Cancel'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import HistoryIcon from '@material-ui/icons/History'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers'
import { callCam, callServer } from '../utils'
import moment from 'moment'
import {red, green, yellow} from '@material-ui/core/colors'
import WifiModal from '../components/WifiModal'
import MyToolTip from '../components/MyToolTip'
import { Link } from 'react-router-dom'

import ConfirmModal from '../components/ConfirmModal'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
    facepaper: {
        padding: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    button: {
        margin: theme.spacing(1),
    },
}))

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box minWidth={65}>
                <Typography variant="caption" display="block" gutterBottom>
                    {props.name}
                </Typography>
            </Box>
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    )
}

function SubmitButton(props) {
    const status = props.status
    const onClick = props.onClick
    const enable = props.enable
    if(status === "success")
        return (
            <IconButton
                onClick={onClick}
                disabled={!enable}
            >
                <CheckCircleIcon style={{ color: green[500] }}/>
            </IconButton>
        )
    else if(status === "fail")
        return (
            <IconButton
                onClick={onClick}
                disabled={!enable}
            >
                <CancelIcon style={{ color: red[500] }}/>
            </IconButton>
        )
    else
        return (
            <IconButton
                onClick={onClick}
                disabled={!enable}
            >
                <ArrowForwardIcon/>
            </IconButton>
        )
}

const NotiEntry = (props) => {

    const session = useSession()
    const cameraId = useCameraId()

    const tagId = props.tagId
    const tag = props.tag
    const [url, setUrl] = useState(props.url)

    const [notificationUrlSubmitStatus, setNotificationUrlSubmitStatus] = useState("")

    const handleNotiWebhookChange = (e) => { setUrl(e.target.value) }
    const handleNotiWebhookSubmit = () => {
        const fetch = async () => {
            let payload = {
                tagId: tagId,
                webhook: url
            }
            const res = await callCam(session, cameraId, 'config.notification.webhook.set', payload)
            setNotificationUrlSubmitStatus(!res || !res.ok ? "fail" : "success")

            // show status for 3 sec
            setTimeout(() => {
                setNotificationUrlSubmitStatus("")
            }, 3000)
        }
        fetch()
    }

    return (
        <React.Fragment>
            <Grid item xs={3}>
                <TextField
                    label="Tag"
                    value={tag}
                    InputProps={{readOnly: true}}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={9}>
                <FormControl fullWidth>
                    <InputLabel>Webhook URL</InputLabel>
                    <Input type='text' value={url} placeholder="https://www.my-webhook.com" onChange={handleNotiWebhookChange} endAdornment={
                        <InputAdornment position="end">
                            <SubmitButton
                                status={notificationUrlSubmitStatus}
                                enable={true}
                                onClick={handleNotiWebhookSubmit}
                            />
                        </InputAdornment>
                    }/>
                </FormControl>
            </Grid>
        </React.Fragment>
    )
}

const UpdateConfirmDialog = (props) => {
    const { onClose, open, ...other } = props

    const handleCancel = () => { onClose(false) }
    const handleOk = () => { onClose(true) }

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={open}
        {...other}
      >
        <DialogTitle >Update Firmware</DialogTitle>
        <DialogContent dividers>
            <Grid container>
                <Grid item xs={2}>
                    <WarningIcon style={{ color: yellow[700], fontSize: 40 }}></WarningIcon>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="body1" gutterBottom>
                        Camera will reboot and becomes offline for a few minutes. Confirm?
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary"> Cancel </Button>
          <Button onClick={handleOk} color="primary"> Ok </Button>
        </DialogActions>
      </Dialog>
    )
}

const CountDownDialog = (props) => {
    const { duration, open, ...other } = props
    const [sec, setSec] = useState(duration)

    useEffect(() => {
        if (!open)
            return
        if(sec > 0){
            setTimeout(() => { setSec(sec - 1) }, 1000)
            return
        }
        console.log('reload now')
        window.location.reload()
    }, [open, sec])

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle >Update Firmware</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    <Grid item xs={2}>
                        <WarningIcon style={{ color: yellow[700], fontSize: 40 }}></WarningIcon>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body1" gutterBottom>
                            Page will automaticaly refresh in {sec} seconds...
                      </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const TokenDialog = (props) => {
    const { onClose, open, ...other } = props

    const [token, setToken] = useState('')

    const handleCancel = () => { onClose(false, token) }
    const handleOk = () => { onClose(true, token) }

    const handleTokenChange = (e) => setToken(e.target.value)

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={open}
        {...other}
      >
        <DialogTitle>username:password</DialogTitle>
        <DialogContent dividers>
            <TextField
                label="token"
                type="password"
                onChange={handleTokenChange}
            />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary"> Cancel </Button>
          <Button onClick={handleOk} color="primary"> Ok </Button>
        </DialogActions>
      </Dialog>
    )
}
export default function SettingGeneralTab(props) {
    const classes = useStyles()
    const session = useSession()
    const TIME_FORMAT = "HH:mm:ssZ"
    const cameraId = useCameraId()
    const username = localStorage.getItem('username')
    const [camName, setCamName] = useState('')
    const [camNameSubmitStatus, setCamNameSubmitStatus] = useState("")
    const [led, setLed] = useState(false)
    const [detectionSound, setDetectionSound] = useState(false)
    const [wifiName, setWifiName] = useState('')
    const [internalStorageUsage, setInternalStorageUsage] = useState(0)
    const [sdCardStorageUsage, setSdCardStorageUsage] = useState(0)
    const [relayEnable, setRelayEnable] = useState(false)
    const [alarmEnable, setAlarmEnable] = useState(false)
    const [alarmWebhookUrl, setAlarmWebhookUrl] = useState("")
    const [alarmWebhookUrlSubmitStatus, setAlarmWebhookUrlSubmitStatus] = useState("")
    const [notifications, setNotifications] = useState(null)
    const [batteryPercentage, setBatteryPercentage] = useState(0)
    const [updateVersions, setUpdateVersions] = useState(null)
    const [updateCurrentVersion, setUpdateCurrentVersion] = useState('')
    const [updateOriginalVersion, setUpdateOriginalVersion] = useState('')
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showCountDownModal, setShowCountDownModal] = useState(false)
    const [showTokenDialog, setShowTokenDialog] = useState(false)
    let midNight = new Date()
    midNight.setHours(0,0,0,0)
    const [alarmStartTime, setAlarmStartTime] = useState(midNight)
    const [alarmEndTime, setAlarmEndTime] = useState(midNight)
    const [clearHistoryModalOpen, setClearHistoryModalOpen] = useState(false)
    const [restartModalOpen, setRestartModalOpen] = useState(false)
    const [wifiSettingOpen, setWifiSettingOpen] = useState(false)

    const handleUpdateListReply = (res) => {
        // sort by datetime newest first
        let items = Object.keys(res.versions).map((key) => {
            return [key, res.versions[key]]
        })
        items.sort((first, second) => {
            return first[1].datetime > second[1].datetime ? -1 : 1
        })

        // string format
        items.forEach( i => {
            i[1].string = moment(i[1].datetime).format("YYMMDD - HH:mm") + ' - ' + i[0]
        })

        setUpdateVersions(items)
        if(!res.current)
            res.current = ''
        setUpdateCurrentVersion(res.current)
        setUpdateOriginalVersion(res.current)
    }

    const fetchSetting = async () => {

        let res = await callServer(session, 'camera.get', {camId: cameraId})
        if(res){
            setCamName(res.camera.name)
        }

        res = await callCam(session, cameraId, 'config.network.status')
        if(res){
            let name = res.wifi.name
            let ip = res.wifi.ip
            setWifiName(name + ' (' + ip + ')')
        }

        res = await callCam(session, cameraId, 'config.storage.list')
        if(res){
            setInternalStorageUsage(Number(res.internal.used) / Number(res.internal.size) * 100.0)
            setSdCardStorageUsage(Number(res.sd.used) / Number(res.sd.size) * 100.0)
        }

        res = await callCam(session, cameraId, 'config.relay.get')
        if(res){
            setRelayEnable(res.enable)
        }

        res = await callCam(session, cameraId, 'config.alarm.get')
        if(res){
            let startTime = moment(res.startTime, TIME_FORMAT)
            let endTime = moment(res.endTime, TIME_FORMAT)

            setAlarmEnable(res.enable)
            setAlarmStartTime(startTime.toDate())
            setAlarmEndTime(endTime.toDate())
            setAlarmWebhookUrl(res.webhookUrl)
        }

        res = await callCam(session, cameraId, 'config.feedback.get')
        if(res){
            setLed(res.ledEnable)
            setDetectionSound(res.buzzerEnable)
        }

        res = await callCam(session, cameraId, 'config.battery.get')
        if(res){
            let v = res.voltage
            v = (v - 2.9) / (3.8 - 2.9) * 100

            // clamp
            if(v > 100) v = 100
            else if(v < 1) v = 1

            setBatteryPercentage(v)
        }

        res = await callCam(session, cameraId, 'config.notification.get')
        if(res){
            setNotifications(res.notifications)
        }

        res = await callCam(session, cameraId, 'config.update.list')
        if(res){
            handleUpdateListReply(res)
        }
    }
    const fetchDataCallback = useCallback(fetchSetting, [session, cameraId])

    useEffect(() => {
        fetchDataCallback()
    }, [fetchDataCallback])

    // camera name
    const handleCamNameChange = (e) => { setCamName(e.target.value) }
    const handleCamNameSubmit = () => {
        const fetch = async () => {
            let payload = {
                camId: cameraId,
                camName: camName
            }
            const res = await callServer(session, 'camera.name.set', payload)
            // set status
            if(!res || !res.ok)
                setCamNameSubmitStatus("fail")
            else
                setCamNameSubmitStatus("success")

            // show status for 3 sec
            setTimeout(() => { 
                setCamNameSubmitStatus("")
            }, 3000)
        }
        fetch()
    }

    // feedback
    const handleLedChange = async (e, v) => {
        let payload = {
            enable: v
        }
        const res = await callCam(session, cameraId, 'config.led.enable.set', payload)
        if(!res)
            return
        setLed(v)
    }

    const handleDetectionSoundChange = async (e, v) => {
        let payload = {
            enable: v
        }
        const res = await callCam(session, cameraId, 'config.buzzer.enable.set', payload)
        if(!res)
            return
        setDetectionSound(v)
    }

    // alarm enable
    const handleAlarmEnableChange = async (e, v) => {
        let payload = {
            enable: v
        }
        const res = await callCam(session, cameraId, 'config.alarm.enable.set', payload)
        if(!res)
            return
        setAlarmEnable(v)
    }

    // alarm webhook
    const handleWebhookUrlChange = (e) => { setAlarmWebhookUrl(e.target.value) }
    const handleWebhookUrlSubmit = () => {
        const fetch = async () => {
            let payload = {
                webhookUrl: alarmWebhookUrl
            }
            const res = await callCam(session, cameraId, 'config.alarm.webhookurl.set', payload)
            // set status
            if(!res || !res.ok)
                setAlarmWebhookUrlSubmitStatus("fail")
            else
                setAlarmWebhookUrlSubmitStatus("success")

            // show status for 3 sec
            setTimeout(() => { 
                setAlarmWebhookUrlSubmitStatus("")
            }, 3000)
        }
        fetch()
    }

    // alarm time
    const handleAlarmStartTimeChange = (d) => { setAlarmStartTime(d) }
    const handleAlarmEndTimeChange = (d) => { setAlarmEndTime(d) }
    const updateAlarmTime = () => {
        let payload = {
            alarmStartTime: moment(alarmStartTime).format(TIME_FORMAT),
            alarmEndTime: moment(alarmEndTime).format(TIME_FORMAT)
        }
        callCam(session, cameraId, 'config.alarm.time.set', payload)
    }

    const handleRelayChange = async (e, v) => {
        let payload = {
            enable: v
        }
        const res = await callCam(session, cameraId, 'config.relay.enable.set', payload)
        if(!res)
            return
        setRelayEnable(v)
    }

    const handleVersionSelect = (e) => { setUpdateCurrentVersion(e.target.value) }
    const handleVersionChangeClick = () => {
        if(!updateCurrentVersion || updateCurrentVersion === updateOriginalVersion)
            return
        setShowUpdateModal(true)
    }
    const handleUpdateConfirmDialogClose = (ok) => {
        if(!ok){
            setShowUpdateModal(false)
            return
        }
        const payload = {
            version: updateCurrentVersion
        }
        const res = callCam(session, cameraId, 'config.update.change', payload)
        if(!res || !res.ok){
            console.log('ERROR: failed to change version')
        }

        setShowCountDownModal(true)
    }
    const handleUpdateCheckClick = (e) => {
        if (e.ctrlKey) {
            setShowTokenDialog(true)
            return
        }

        handleTokenDialogClose(true, null)
    }
    const handleTokenDialogClose = async (ok, token) => {
        setShowTokenDialog(false)
        if(!ok){
            return
        }

        setUpdateVersions(null)
        setUpdateCurrentVersion('')
        setUpdateOriginalVersion('')

        const payload = token ? {token: token} : {}
        let res = await callCam(session, cameraId, 'config.update.fetch', payload)
        if(!res || !res.ok){
            console.log('ERROR: failed to fetch update retry with config.update.list')
            res = await callCam(session, cameraId, 'config.update.list')
        }
        if(res)
            handleUpdateListReply(res)
    }

    // clear history button
    const handleClearHistoryClick = () => { setClearHistoryModalOpen(true) }
    const handleClearHistoryModalClose = () => {setClearHistoryModalOpen(false) }
    const handleClearHistoryConfirm = () => {
        console.log('clear history is not implemented')
    }

    // restart button
    const handleRestartClick = () => { setRestartModalOpen(true) }
    const handleRestartModalClose = () => {setRestartModalOpen(false) }
    const handleRestartConfirm = () => {
        callCam(session, cameraId, 'config.softrestart')
    }
    const handleOpenWifi = () => { setWifiSettingOpen(true) }
    const handleCloseWifi = () => { setWifiSettingOpen(false) }

    return !cameraId ? null : (
        <div className={classes.root}>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h4" gutterBottom>
                            General
                        </Typography>

                        <FormControl fullWidth>
                            <InputLabel>Camera Name</InputLabel>
                            <Input type='text' value={camName} onChange={handleCamNameChange} endAdornment={
                                    <InputAdornment position="end">
                                        <SubmitButton
                                            status={camNameSubmitStatus}
                                            onClick={handleCamNameSubmit}
                                            enable={true}
                                        />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h4" gutterBottom>
                            Storage
                        </Typography>

                        <LinearProgressWithLabel name='Internal' value={internalStorageUsage} />
                        <LinearProgressWithLabel name='SD Card' value={sdCardStorageUsage} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h4" gutterBottom>
                            Battery
                        </Typography>

                        <LinearProgressWithLabel name='Level' value={batteryPercentage} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h4" gutterBottom>
                            Network
                        </Typography>

                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Box fontFamily="Monospace" fontSize="h6.fontSize">
                                {wifiName}
                            </Box>
                            <IconButton onClick={handleOpenWifi}>
                                <SettingsIcon />
                            </IconButton>
                        </Grid>
                    </Paper>
                    <WifiModal open={wifiSettingOpen} onClose={handleCloseWifi} />
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h4" gutterBottom>
                            Feedback
                        </Typography>

                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Typography>
                                Detection Blink
                            </Typography>
                            <Switch checked={led} onChange={handleLedChange} name="checkedA" color="primary" />
                        </Grid>

                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Typography>
                                Detection Sound
                            </Typography>
                            <Switch checked={detectionSound} onChange={handleDetectionSoundChange} name="checkedA" color="primary" />
                        </Grid>

                    </Paper>
                    <WifiModal open={wifiSettingOpen} onClose={handleCloseWifi} />
                </Grid>

                {username && username === 'admin@lumio3d.com' &&
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={3}>
                            <Typography variant="h4" gutterBottom>
                                Door
                            </Typography>

                            <Grid container direction="row" justify="space-between" alignItems="center" >
                                <Typography>
                                    Enable Door Control
                                    <MyToolTip title="Enable Face Unlock and web interface button in Live View" />
                                </Typography>
                                <Switch checked={relayEnable} onChange={handleRelayChange} color="primary" />
                            </Grid>
                        </Paper>
                    </Grid>
                }

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>

                        <Grid container direction="row" justify="space-between" alignItems="center" >
                            <Typography variant="h4" gutterBottom>
                                Alarm
                                <MyToolTip title="Detections during Alarm Period will be reported via webhook. 'Security' tag is excluded."/>
                            </Typography>
                            <Switch checked={alarmEnable} onChange={handleAlarmEnableChange} name="checkedA" color="primary" />
                        </Grid>

                        <FormControl fullWidth>
                            <InputLabel>Webhook URL</InputLabel>
                            <Input type='text' value={alarmWebhookUrl} placeholder="https://www.my-webhook.com" onChange={handleWebhookUrlChange} disabled={!alarmEnable} endAdornment={
                                <InputAdornment position="end">
                                    <SubmitButton
                                        status={alarmWebhookUrlSubmitStatus}
                                        enable={alarmEnable}
                                        onClick={handleWebhookUrlSubmit}
                                    />
                                </InputAdornment>
                            }/>
                        </FormControl>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around" spacing={2}>
                                <Grid item xs={6}>
                                    <KeyboardTimePicker
                                        margin="normal"
                                        variant="inline"
                                        id="alarmStartTime"
                                        label="Alarm Period Begin"
                                        value={alarmStartTime}
                                        onChange={handleAlarmStartTimeChange}
                                        format="HH:mm"
                                        fullWidth
                                        onClose={updateAlarmTime}
                                        disabled={!alarmEnable}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardTimePicker
                                        margin="normal"
                                        variant="inline"
                                        id="alarmEndTime"
                                        label="Alarm Period End"
                                        value={alarmEndTime}
                                        onChange={handleAlarmEndTimeChange}
                                        format="HH:mm"
                                        fullWidth
                                        onClose={updateAlarmTime}
                                        disabled={!alarmEnable}
                                    />
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>

                        <Grid container direction="row" justify="space-between" alignItems="center">
                            <Typography variant="h4" gutterBottom>
                                Notification
                                <MyToolTip title="Detections with these tags will be reported via the specified webhook."/>
                            </Typography>
                        </Grid>

                        <Grid container justify="space-around" spacing={2}>
                            {notifications == null &&
                                <CircularProgress size={24} />
                            }
                            {notifications && Object.keys(notifications).length === 0 &&
                                <p>There is no Tag assiciated to any Users. Please add one in <Link to="/home/contacts">Contact</Link>.</p>
                            }
                            {notifications && Object.entries(notifications).map((v) => {
                                return <NotiEntry key={v[0]} tagId={v[0]} tag={v[1].tag} url={v[1].notiWebhook} />
                            })}
                        </Grid>
                        
                    </Paper>
                </Grid>

                {username && username === 'admin@lumio3d.com' && <React.Fragment>
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={3}>

                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Typography variant="h4" gutterBottom>
                                    Update
                                </Typography>
                            </Grid>

                            {updateVersions == null &&
                                <Grid container justify="space-around" spacing={2} alignItems="center">
                                    <CircularProgress size={24} />
                                </Grid>
                            }
                            {updateVersions &&
                                <React.Fragment>
                                    <Grid container justify="flex-start" spacing={2} alignItems="center">
                                        <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                            <Button onClick={handleUpdateCheckClick} variant="contained" color='secondary'>
                                                Check for Updates
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item xs={10} sm={10} md={11} lg={11} xl={11}>
                                            <FormControl className={classes.formControl} fullWidth>
                                                <InputLabel>Available Version</InputLabel>
                                                <Select
                                                    value={updateCurrentVersion}
                                                    onChange={handleVersionSelect}
                                                >
                                                {updateVersions.map((v) => {
                                                    return <MenuItem key={v[0]} value={v[0]}>{v[1].string}</MenuItem>
                                                })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                            <IconButton onClick={handleVersionChangeClick} >
                                                <ArrowForwardIcon/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }

                            <UpdateConfirmDialog open={showUpdateModal} onClose={handleUpdateConfirmDialogClose} />
                            <CountDownDialog open={showCountDownModal} duration={120}/>
                            <TokenDialog open={showTokenDialog} onClose={handleTokenDialogClose} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={3}>
                            <Typography variant="h4" gutterBottom>
                                Maintenance
                            </Typography>

                            <Grid container justify="flex-start" spacing={2}>
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    startIcon={<HistoryIcon />}
                                    onClick={handleClearHistoryClick}
                                >
                                    Clear History
                                </Button>
                                <ConfirmModal open={clearHistoryModalOpen} onClose={handleClearHistoryModalClose} title="Clear History?" subtitle="All data will be lost. This action is permanent." onConfirm={handleClearHistoryConfirm}/>

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<PowerSettingsNewIcon />}
                                    onClick={handleRestartClick}
                                >
                                    Restart
                                </Button>
                                <ConfirmModal open={restartModalOpen} onClose={handleRestartModalClose} title="Restart?" subtitle="Camera will be temporary offline for a few minutes." onConfirm={handleRestartConfirm}/>
                            </Grid>
                        </Paper>
                    </Grid>
                </React.Fragment>}
            </Grid>

        </div>
    )
}
