import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import moment from 'moment'
import { useCameraId, useSession } from '../components/AutobahnSocket'
import AnalyticSetting from '../components/AnalyticSetting'
import { SmartCamContext } from '../components/SmartCamContext'
import { WaittimeChartDaily, WaittimeChartDistribution } from '../components/WaittimeChart'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { CircularProgress } from '@material-ui/core'
import {callCam} from '../utils'
import TrackInfiniteScroll from '../components/TrackInfiniteScroll'
import VideoModal from '../components/VideoModal'
import VideoView from '../components/VideoView'
import MyToolTip from '../components/MyToolTip'
import useQueryString, { momentParser } from '../useQueryString'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    settingPaper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paper: {
        padding: theme.spacing(1),
        height: "100%"
    },
    graph: {
        padding: theme.spacing(2),
    },
}))

export default function Waittime () {
    const classes = useStyles()
    const session = useSession()
    const cameraId = useCameraId()

    // qs
    const [toDate, setToDate, restoreToDate] = useQueryString('toDate', moment().endOf('day'), momentParser)
    
    // register back button
    window.onpopstate = () => {
        restoreToDate()
    }

    const [plotType, setPlotType] = useState('daily')
    const [plotData, setPlotData] = useState()
    const [plotDataIdx, setPlotDataIdx] = useState(null)
    const [loading, setLoading] = useState(false)
    const [videoData, setVideoData] = useState(null)
    const [validPeriod, setValidPeriod] = useState()

    const fromDate = moment(toDate).subtract(6, 'days').startOf('day')

    const smartCamContext = useRef(useContext(SmartCamContext))
    useEffect(()=> {
        smartCamContext.current.setTitle('Visit Duration')
        return () => {
            window.onpopstate = null
        }
    }, [])

    const fetchWaittime = async () => {

        if (!cameraId || !fromDate || !toDate || plotType === 'heatmap')
            return

        setValidPeriod()
        setPlotData()
        setPlotDataIdx(null)
        setLoading(true)

        // query valid video date
        let res = await callCam(session, cameraId, 'video.validperiod')
        if (!res) {
            setLoading(false)
            return
        }
        setValidPeriod(res.period)

        // query this period
        let payload = {
            fromDate: fromDate.format(),
            toDate: toDate.format()
        }
        res = await callCam(session, cameraId, 'stat.waittime', payload)
        if (!res) {
            setLoading(false)
            return
        }
        
        // compute stat
        const statResult = res.waittime

        let plotDataa = []
        if (plotType === 'daily'){

            // create buckets
            const num = [...Array(toDate.diff(fromDate, 'days') + 1).keys()]
            plotDataa = num.map( i => {
                const today = moment(fromDate).add(i, 'days')
                return { durationText: today.format('ddd D MMM'), durations: [], trackIds:[], average: 0 }
            })

            // group by bucket
            statResult.forEach((s) => {
                const idx = moment(s.arrivalTime).diff(fromDate, 'days')
                plotDataa[idx].durations.push(s.waitingTime)
                plotDataa[idx].trackIds.push(s.trackId)
            })
            
            // compute average
            plotDataa.forEach(row => {
                if(row.durations.length > 0)
                    row.average = row.durations.reduce((a, b) => a + b, 0) / row.durations.length
            })
        }
        else if (plotType === 'distribution') {
            // create buckets
            // count is 1 because we will do log scale chart
            plotDataa = [
                { durationText: '< 5 mins',    durationMin: 5,    trackIds:[], count: 1 },
                { durationText: '< 10 mins',   durationMin: 10,   trackIds:[], count: 1 },
                { durationText: '< 30 mins',   durationMin: 30,   trackIds:[], count: 1 },
                { durationText: '< 1 hour',    durationMin: 60,   trackIds:[], count: 1 },
                { durationText: '< 2 hours',   durationMin: 120,  trackIds:[], count: 1 },
                { durationText: '< 3 hours',   durationMin: 180,  trackIds:[], count: 1 },
                { durationText: 'long hours',  durationMin: 9999, trackIds:[], count: 1 },
            ]

            // group by bucket
            statResult.forEach((s) => {
                const mins = parseInt(s.waitingTime / 60)
                for (let i=0; i < plotDataa.length; ++i) {
                    if (mins < plotDataa[i].durationMin) {
                        ++plotDataa[i].count
                        plotDataa[i].trackIds.push(s.trackId)
                        break
                    }
                }
            })
        }
        setPlotData(plotDataa)
        setLoading(false)
    }
    const fetchWaittimeCallback = useCallback(fetchWaittime, [session, cameraId, toDate, plotType])

    useEffect(()=> {
        fetchWaittimeCallback()
    }, [fetchWaittimeCallback])

    const handleSettingChange = (toDate) => {
        setToDate(toDate)
    }

    const handlePlotTypeChange = (newType) => {
        setPlotType(newType)
    }

    const handleChartClick = (index) => {
        if(index < 0 || index >= plotData.length)
            return

        setPlotDataIdx(index === plotDataIdx ? null : index)
    }

    // play video modal 
    const handleFacecardClicked = (info) => {
        const data = {
            camId: cameraId,
            datetime: moment(info.start),
        }
        setVideoData(data)
    }

    const handleVideoModalClose = () => {
        setVideoData(null)
    }

    return (
    <div className={classes.root}>
        <Grid container spacing={3}>

            {/* left control */}
            <Grid item xs={12} sm={12} md={4} lg={2} xl={2}>
                <Paper className={classes.settingPaper}>
                    <AnalyticSetting toDate={toDate} onChange={handleSettingChange}/>
                </Paper>
            </Grid>

            {/* main content */}
            <Grid item xs={12} sm={12} md={8} lg={10} xl={10}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>

                        {/* tab & graph */}
                        <Paper className={classes.paper}>
                            <Tabs
                                value={plotType}
                                onChange={(event, newValue) => handlePlotTypeChange(newValue)}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                            >
                                <Tab label="Visit Duration" value='daily'/>
                                <Tab label="Histogram" value='distribution'/>
                                <Tab label="Heatmap" value='heatmap'/>
                            </Tabs>

                            { loading ? 
                                <CircularProgress style={{marginLeft: '50%', marginTop:'5%'}}/>
                            :
                                <React.Fragment>
                                {plotData &&
                                    <React.Fragment>
                                        { plotType === 'daily' && <AverageWaitTime fromDate={fromDate} toDate={toDate} validPeriod={validPeriod} plotData={plotData} plotDataIdx={plotDataIdx} handleChartClick={handleChartClick} handleFacecardClicked={handleFacecardClicked}/> }
                                        { plotType === 'distribution' && <CountWaitTime fromDate={fromDate} toDate={toDate} validPeriod={validPeriod} plotData={plotData} plotDataIdx={plotDataIdx} handleChartClick={handleChartClick} handleFacecardClicked={handleFacecardClicked}/> }
                                        { plotType === 'heatmap' && <HeatMap fromDate={fromDate} toDate={toDate} />}
                                    </React.Fragment>
                                }
                                </React.Fragment>
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        {/* video modal */}
        <VideoModal title='Playback' open={!!videoData} onClose={handleVideoModalClose} datetime={ videoData && videoData.datetime.toDate()} />
    </div>
    )
}

const AverageWaitTime = (props) => {
    const classes = useStyles()
    const fromDate = props.fromDate
    const toDate = props.toDate
    const plotData = props.plotData
    const plotDataIdx = props.plotDataIdx
    const faces = plotData && plotDataIdx ? plotData[plotDataIdx].trackIds : []
    const handleChartClick = props.handleChartClick
    const handleFacecardClicked = props.handleFacecardClicked
    const validPeriod = props.validPeriod

    return (
        <div className={classes.graph}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom  style={{marginTop: "1rem"}}>
                        Visit Duration
                        <MyToolTip title="Click on the bar to show more information."></MyToolTip>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {fromDate.format('ddd D MMM')} - {toDate.format('ddd D MMM')}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{height: '200px', width: '100%'}}>
                    <WaittimeChartDaily data={plotData} onClick={handleChartClick} selectedIdx={plotDataIdx}/>
                </Grid>
            </Grid>

            <TrackInfiniteScroll trackIds={faces} onCardClicked={handleFacecardClicked} validPeriod={validPeriod}/>
        </div>
    )
}

const CountWaitTime = (props) => {
    const classes = useStyles()
    const fromDate = props.fromDate
    const toDate = props.toDate
    const plotData = props.plotData
    const plotDataIdx = props.plotDataIdx
    const faces = plotData && plotDataIdx ? plotData[plotDataIdx].trackIds : []
    const handleChartClick = props.handleChartClick
    const handleFacecardClicked = props.handleFacecardClicked
    const validPeriod = props.validPeriod

    return (
        <div className={classes.graph}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom  style={{marginTop: "1rem"}}>
                        Histogram
                        <MyToolTip title="Click on the bar to show more information."></MyToolTip>
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        {fromDate.format('ddd D MMM')} - {toDate.format('ddd D MMM')}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{height: '200px', width: '100%'}}>
                    <WaittimeChartDistribution data={plotData} onClick={handleChartClick} selectedIdx={plotDataIdx}/>
                </Grid>
            </Grid>

            <TrackInfiniteScroll trackIds={faces} onCardClicked={handleFacecardClicked} validPeriod={validPeriod}/>
        </div>
    )
}

const HeatMap = (props) => {
    const session = useSession()
    const cameraId = useCameraId()
    const rtcRef = useRef()
    const [heatmap, setHeatmap] = useState()
    const [loading, setLoading] = useState()
    const fromDate = props.fromDate
    const toDate = props.toDate

    const fetchHeatmap = async () => {
        console.log('fetchheaptmap')
        if(!fromDate || !toDate || !session)
            return

        setLoading(true)

        let payload = {
            fromDate: fromDate.format(),
            toDate: toDate.format(),
        }
        let res = await callCam(session, cameraId, 'stat.heatmap', payload)
        if (!res)
            return

        setLoading(false)
        setHeatmap(res.b64Png)
    }
    const fetchHeatmapCallback = useCallback(fetchHeatmap, [session, cameraId, fromDate, toDate])

    useEffect(() => {
        fetchHeatmapCallback()
    }, [fetchHeatmapCallback])

    return (
        <Grid container>
            <Grid item xs={12} style={{marginTop:'16px'}}>  {/* for spacing */}
                {loading && <CircularProgress style={{marginLeft: '50%', marginTop:'5%'}}/>}
            </Grid>
            <Grid item xs={12}>
                <div style={{position:'relative', margin:'1rem'}} >
                    <img src={heatmap} style={{width: '100%', position:'absolute', 'zIndex':2}} alt=''/>
                    <VideoView rtcRef={rtcRef} onReadyCallback={null}/>
                </div>
            </Grid>
        </Grid>
    )
}