import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useSession } from './AutobahnSocket';

import { callCam } from '../utils'

const filter = createFilterOptions();

export default function TagAutocomplete({ cameraId, labelId, onTagUpdate }) {
    const session = useSession()
    const [labels, setLabels] = useState([])
    const [tagId, setTag] = useState(-1)

    useEffect( () => {
        fetchLabel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[cameraId])

    async function fetchLabel() {
        const res = await callCam(session, cameraId, 'tag.list')
        if (!res || !res.ok)
            return
        let tags = []
        res.tags.map( tag => {
            if ( tag.name.substring(0,7) !== 'Reserve') {
                tags.push(tag)
            }
            return tag
        })
        setLabels(tags)
    }

    async function labelAddTag(tagId) {
        const payload = {
            labelId: labelId,
            tagId: tagId
        }
        const res = await callCam(session, cameraId, 'label.addtag', payload)
        if (!res || !res.ok){
            console.log('ERROR label addtag')
        }
        onTagUpdate()
        await fetchLabel()
    }

    return (
        <React.Fragment>
        <Autocomplete
            key={tagId}
            onChange={ async (event, newValue) => {

                if (!newValue) {
                    // user press clear 'x'
                    return
                }

                let tagId
                if (newValue && newValue.inputValue) {
                    // create new tag
                    let payload = {
                        tag: newValue.inputValue
                    }
                    let res = await callCam(session, cameraId, 'tag.create', payload)
                    if (!res || !res.ok) {
                        console.log('ERROR tag create')
                        return
                    }
                    tagId = res.tagId
                } else {
                    // old label
                    tagId = newValue.id
                } 

                if (!tagId) {
                    console.log('ERROR something has gone wrong in tag autocomplete')
                    return
                }

                await labelAddTag(tagId)
                setTag(tagId)
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '' ) {
                    filtered.push({
                        inputValue: params.inputValue,
                        name: `Create "${params.inputValue}"`,
                    });
                }

                return filtered;
            }}
            id="free-solo-dialog-demo"
            options={labels}
            getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string') {
                return option;
            }
            if (option.inputValue) {
                return option.inputValue;
            }
            return option.name;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(option) => option.name}
            style={{ width: 200 }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="Add tag here" variant="outlined" />
            )}
        />
        
        </React.Fragment>
    );
}
