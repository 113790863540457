import React, {useState, useEffect, useContext, useRef, useCallback} from 'react'
import qs from 'query-string'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import 'date-fns'
import Paper from '@material-ui/core/Paper'
import VideoModal from '../components/VideoModal'
import { FacecardJpgOnly } from '../components/Facecard'
import AddFaceModal from '../components/AddFaceModal'
import FaceTimelineChart from '../components/FaceTimelineChart'
import IdCard from '../components/IdCard'
import EditFaceModal from '../components/EditFaceModal'
import DeleteFaceModal from '../components/DeleteFaceModal'
import { CircularProgress, Typography } from '@material-ui/core'

import { useCameraId, useSession } from '../components/AutobahnSocket'
import { SmartCamContext } from '../components/SmartCamContext'
import { callCam } from '../utils'
import useQueryString, { momentParser } from '../useQueryString'


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    loading: {
        left: '50%'
    },
    divider:{
        padding: theme.spacing(1)
    }
}))

const JoinedTrackSection = (props) => {
    const classes = useStyles()

    const joinedTracks = props.joinedTracks
    const isKnownFace = props.isKnownFace
    const addFace = props.addFace
    const onClick = props.onClick
    const selectedDate = props.selectedDate
    const validPeriod = props.validPeriod
    const resolution = props.resolution

    const [filtered, setFiltered] = useState()

    useEffect(() => {
        const newSections = Object.keys(joinedTracks).reduce((acc, key) => {

            // skip empty
            if(!joinedTracks[key].length)
                return acc

            // if selectedDate, skip all but the selected date
            if(selectedDate && selectedDate.format() !== key)
                return acc

            acc.push({
                date: key,
                tracks: joinedTracks[key]
            })
            return acc
        }, [])
        setFiltered(newSections)
    }, [joinedTracks, selectedDate])

    const datetimeFormat = resolution === 'Week' ? 'ddd D MMM' : 'ddd D MMM HH:00'
    return (
        <Grid container spacing={3} direction="column" alignItems="flex-start">
            { filtered && filtered.map((f, i) => {
                return (
                    <Grid item key={i}>
                    <Paper className={classes.paper}>
                        <Typography variant="h2" gutterBottom>
                            {moment(f.date).format(datetimeFormat)}
                        </Typography>

                        <Grid container>
                            {f.tracks.map((tt, idx) => {
                                return (
                                    <Grid item key={idx} >
                                        <FacecardJpgOnly trackIds={tt.trackIds} isKnownFace={isKnownFace} start={tt.start} end={tt.end} addFace={addFace} onClick={onClick} validPeriod={validPeriod}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Paper>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default function FaceHistory() {
    // global
    const classes = useStyles()
    const history = useHistory()
    const session = useSession()
    const cameraId = useCameraId()

    // qs
    /* eslint-disable no-unused-vars */
    const [labelId, setLabelId, restoreLabelId] = useQueryString('labelId', null)           // this initial value is amust
    const [toDate, setToDate, restoreToDate] = useQueryString('toDate', null, momentParser) // this initial value is amust
    const [resolution, setResolution, restoreResolution] = useQueryString('resolution', 'Week')
    const [selectedDate, setSelectedDate, restoreSelectedDate] = useQueryString('selectedDate', null, momentParser)
    /* eslint-disable no-unused-vars */

    // register back button
    window.onpopstate = () => {
        restoreLabelId()
        restoreToDate()
        restoreResolution()
        restoreSelectedDate()
    }

    // fetched data
    const [personInfo, setPersonInfo] = useState()
    const [chartData, setChartData] = useState()
    const [joinedTracks, setJoinedTracks] = useState([])

    const [videoData, setVideoData] = useState(null)
    const [refreshIdCard, setRefreshIdCard] = useState(false)
    const [validPeriod, setValidPeriod] = useState()

    // add, edit, delete face
    const [addingTrackIds, setAddingTrackIds] = useState(null)
    const [editingLabelId, setEditingLabelId] = useState(null)
    const [deletingLabelId, setDeletingLabelId] = useState(null)

    // title bar
    const smartCamContext = useRef(useContext(SmartCamContext))
    useEffect(()=> {
        smartCamContext.current.setTitle('Face History')
    },[])

    const fetchTracks = async () => {

        if (!labelId)
            return

        // query valid video date
        let res = await callCam(session, cameraId, 'video.validperiod')
        if (!res)
            return
        setValidPeriod(res.period)

        const Q = 30 * 60 * 1000    // milliseconds
        const fromDate = resolution === 'Week' ? moment(toDate).subtract(6, 'days').startOf('day') : moment(toDate).startOf('day')
        const stepUnit = resolution === 'Week' ? 'days' : 'hours'
        let currentDate = moment(fromDate)
        let newJoinedTracks = {}
        let chartDataa = []
        while (currentDate < toDate) {

            const fromTime = currentDate.format()
            const toTime = moment(currentDate).add(1, stepUnit).format()

            let payload = {
                fromTime: fromTime,
                toTime: toTime,
                labelId: labelId
            }

            let res = await callCam(session, cameraId, 'track.findbetweentimewithlabel', payload)
            if (!res)
                return

            // sort tracks by start time
            // this is how sick js is to sort string
            res.tracks.sort((a, b) => {
                if (a.start < b.start)      return -1
                else if (a.start > b.start) return 1
                else                        return 0
            })

            // form chartData
            const trackIds = res.tracks.reduce((acc, value) => {
                acc.push(value.trackId)
                return acc
            }, [])

            chartDataa.push({
                date: fromTime,
                tracks: trackIds,
                countVisitor: trackIds.length
            })

            // join tracks: grouping tracks nearby together into single entry
            const joinedTracksThisDate = []
            let i = 0
            while(i < res.tracks.length)
            {
                const thisTrack = res.tracks[i]
                thisTrack.trackIds = [thisTrack.trackId]
                delete thisTrack.trackId
                ++i

                // // keep joining while possible
                // while(i < res.tracks.length)
                // {
                //     let nextTrack = res.tracks[i]
                //     let dt = moment(nextTrack.start).diff(moment(thisTrack.end))    // milliseconds

                //     // break if not joinable
                //     if(dt > Q)
                //         break

                //     thisTrack.end = nextTrack.end
                //     thisTrack.trackIds.push(nextTrack.trackId)
                //     ++i
                // }
                joinedTracksThisDate.push(thisTrack)
            }
            newJoinedTracks[fromTime] = joinedTracksThisDate
            currentDate = moment(toTime)
        }
        setChartData(chartDataa)
        
        // sort reverse newJoinedTracks (newest first)
        const reversed = Object.keys(newJoinedTracks).reverse().reduce((acc, value) => { 
            acc[value] = newJoinedTracks[value] 
            return acc
        }, {})
        setJoinedTracks(reversed)
    }
    const fetchTracksCallback = useCallback(fetchTracks, [session, cameraId, labelId])

    const fetchPersonInfo = async () => {

        if (!labelId)
            return

        const payload = {
            id: labelId
        }
        const res = await callCam(session, cameraId, 'label.findbyid', payload)
        if (!res)
            return
        const label = res.label
        if (!label.name || label.name === '') {
            label.name = null
        }
        setPersonInfo(label)
    }
    const fetchPersonInfoCallback = useCallback(fetchPersonInfo, [session, cameraId, labelId])

    useEffect(() => {
        fetchPersonInfoCallback()
        fetchTracksCallback()
    }, [fetchTracksCallback, fetchPersonInfoCallback])

    /////////////////////// bar chart

    const handleChartBar = (date) => {
        date = moment(date)
        setSelectedDate(selectedDate && selectedDate.format() === date.format() ? null : date)    // handle deselect
    }

    /////////////////////// video modal

    const handleFacecardClicked = (info) => {
        const data = {
            camId: cameraId,
            datetime: moment(info.start),
        }
        setVideoData(data)
    }

    const handleVideoModalClose = () => {
        setVideoData(null)
    }

    /////////////////////// add face modal

    const addFace = (trackIds) => {
        setAddingTrackIds(trackIds)
    }

    const handleAddFaceClose = () => {
        setAddingTrackIds(null)
    }

    const handleAddFaceSubmit = (labelId) => {
        if(!labelId)
            return

        setAddingTrackIds(null)

        // update to new label id and go!
        setLabelId(labelId)
        const labelIdStr = qs.stringify({'labelId': labelId})
        const toDateStr = qs.stringify({'toDate': toDate.format()})
        const resolutionStr = qs.stringify({'resolution': resolution})
        history.push(`/home/facehistory?${labelIdStr}&${toDateStr}&${resolutionStr}`)
    }

    /////////////////////// edit face modal

    const handleEditClick = (id) => {
        setEditingLabelId(id)
    }

    const handleEditModalClose = () => {
        setEditingLabelId(null)

        const oldValue = refreshIdCard
        setRefreshIdCard(!oldValue)
    }

    /////////////////////// delete face modal

    const handleDeleteClick = (id) => {
        setDeletingLabelId(id)
    }

    const handleDeleteModalClose = () => {
        setDeletingLabelId(null)
    }

    const deleteFace = async () => {
        setDeletingLabelId(null)
        if (!deletingLabelId)
            return

        const payload = {
            labelId: deletingLabelId,
        }
        const res = await callCam(session, cameraId, 'label.delete', payload)
        if (!res)
            return

        const toDateStr = qs.stringify({'toDate': toDate.format()})
        const resolutionStr = qs.stringify({'resolution': resolution})
        history.push(`/home/facetimeline?${toDateStr}&${resolutionStr}`)
    }

    const isLoading = !chartData

    return (
        <div>
            { labelId &&
                <IdCard labelId={labelId} onEditTagClick={handleEditClick} onDeleteClick={handleDeleteClick} refresh={refreshIdCard} style={{margin: 'auto'}}/>
            }

            {isLoading && <CircularProgress size={24} className={classes.loading} />}

            {!isLoading && personInfo &&
                <div>
                    <Paper className={classes.paper}>
                        <Grid item style={{height: '200px', width: '100%'}}>
                            <FaceTimelineChart data={chartData} onBarClick={handleChartBar} resolution={resolution} yLabel="Count (tracks)" selectedDate={selectedDate}/>
                        </Grid>
                    </Paper>

                    <div className={classes.divider}></div>

                    <JoinedTrackSection resolution={resolution} joinedTracks={joinedTracks} isKnownFace={personInfo.name ? true : false} addFace={addFace} onClick={handleFacecardClicked} selectedDate={selectedDate} validPeriod={validPeriod}/>
                </div>
            }

            <VideoModal open={!!videoData} onClose={handleVideoModalClose} datetime={ videoData && videoData.datetime.toDate()} title='Playback' />
            <AddFaceModal open={!!addingTrackIds} onClose={handleAddFaceClose} onSubmit={handleAddFaceSubmit} trackIds={addingTrackIds}/>
            <EditFaceModal open={!!editingLabelId} onClose={handleEditModalClose} labelId={editingLabelId}/>
            <DeleteFaceModal open={!!deletingLabelId} onClose={handleDeleteModalClose} onConfirmDelete={deleteFace} />

        </div>
    )
}


