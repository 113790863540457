import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import VideoViewWithControl from './VideoViewWithControl';
import { useCameraId } from './AutobahnSocket';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    // minWidth: '600px',
    width: '100%',
    // minHeight: '400px'
  },
}))(MuiDialogContent);


export default function VideoModal({ datetime, open, title, onClose }) {

    const cameraId = useCameraId()
    const [videoError, setVideoError] = useState(false)

    useEffect(() => {
        setVideoError(false)
    }, [cameraId, datetime])

    const handleVideoError = () => {
        setVideoError(true)
    }

    return (
        <div>
        <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open} maxWidth='md'>
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
              {title}
            </DialogTitle>
            <DialogContent dividers>
                {cameraId && datetime && !videoError && <VideoViewWithControl camId={cameraId} datetime={datetime} autoLive={false} onVideoError={handleVideoError}/>}
                {videoError && 
                    <Typography variant="subtitle1" gutterBottom>
                        The video has been removed.
                    </Typography>
                }
            </DialogContent>
        </Dialog>
        </div>
    );
}
